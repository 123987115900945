import React from 'react';
import './App.css';
import Sidebar from './Sidebar/Sidebar';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import ProductsPage from './pages/ProductsPage/ProductsPage';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ImagesPage from './pages/ImagesPage/ImagesPage';
import UtilsPage from './pages/UtilsPage/UtilsPage';
import CustomersPage from './pages/CustomersPage/CustomersPage';
import BrandsPage from './pages/BrandPage/BrandPage';
import MetaPage from './pages/MetaPage/MetaPage';

function App() {
    return (
        <Router>
            <div id="app">
                <div className={"h-screen"}>
                    <div className={"flex  flex-row  items-stretch  h-full"}>
                        <Sidebar/>
                        <div className={"bg-gray-100  |  w-full  h-full  |  p-8  |  overflow-y-scroll"}>
                            <Switch>
                                <Route path="/products">
                                    <ProductsPage/>
                                </Route>

                                <Route path="/images">
                                    <ImagesPage/>
                                </Route>

                                <Route path="/customers">
                                    <CustomersPage/>
                                </Route>

                                <Route path="/brands">
                                    <BrandsPage/>
                                </Route>

                                <Route path="/meta">
                                    <MetaPage/>
                                </Route>

                                <Route path="/utils">
                                    <UtilsPage/>
                                </Route>
                            </Switch>
                        </div>
                    </div>
                    <ToastContainer
                        position="bottom-right"
                    />
                </div>
            </div>
        </Router>
    );
}

export default App;
