import React, { Component } from 'react';
import classNames from 'classnames/bind';
import { CgSpinner } from 'react-icons/all';

export enum ButtonStyle {
    Default,
    Secondary,
    Disabled,
    Ghost,
    Negative,
}

export interface ButtonProps {
    loading: boolean;
    className: string;
    onClick: ( event: React.MouseEvent<HTMLButtonElement> ) => void;
    style: ButtonStyle,
}

export default class Button extends Component<ButtonProps, {
    style: ButtonStyle.Default,
}> {

    static defaultProps = {
        className: "",
        loading:   false,
        onClick:   ( e: React.MouseEvent<HTMLButtonElement> ) => {},
        style:     ButtonStyle.Default,
    };

    render() {
        let buttonClasses = classNames( {
            'button  |  flex  flex-row  items-center  justify-center  space-x-2':          true,
            'py-2  px-4  |  font-semibold  text-center  |  border  |  rounded  |  shadow': true,
            'transition-all':                                                              true,
            'is-loading':                                                                  this.props.loading,
            // Styles
            'bg-blue-600  hover:bg-blue-500  |  text-white  |  border-blue-200':                           this.props.style === ButtonStyle.Default,
            'bg-white  hover:bg-gray-100  |  text-gray-800  |  border-gray-400':                           this.props.style === ButtonStyle.Secondary,
            'bg-gray-300  hover:bg-gray-300  |  text-gray-400  |  border-gray-200  |  cursor-not-allowed': this.props.style === ButtonStyle.Disabled,
            'bg-red-600  hover:bg-red-500  |  text-white  |  border-red-200':                              this.props.style === ButtonStyle.Negative,
        } );
        return <button
            disabled={this.props.style === ButtonStyle.Disabled}
            className={buttonClasses + '  |  ' + this.props.className}
            onClick={( e ) => this.props.onClick( e )}>
            {this.props.loading &&
             <CgSpinner size={20} className="spinner  animate-spin"/>
            }
            <span>{this.props.children}</span>
        </button>;
    }
}
