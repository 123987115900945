import React from 'react';
import classNames from 'classnames/bind';
import { Link } from 'react-router-dom';

function SidebarItem( props: { icon: React.ReactNode; label: string; active: boolean; route: string; } ) {
    let itemClasses = classNames( {
        'flex  flex-row  |  items-center  |  py-3  px-4  |  mx-3  mb-1  |  rounded-md  |  cursor-pointer  |': true,
        'bg-gray-300  |':                                                                                     props.active,
        'hover:bg-gray-200  |':                                                                               !props.active,
    } );

    let labelClasses = classNames( {
        'text-m  | font-medium  |  relative  |  text-gray-600  |': true,
    } );

    return (
        <Link to={props.route}>
            <li className={itemClasses}>
                <div className={"mr-2"}>{props.icon}</div>
                <div className={labelClasses}>{props.label}</div>
            </li>
        </Link>
    );
}

SidebarItem.defaultProps = {
    icon:   null,
    label:  '',
    active: false,
    route:  '/',
};

export default SidebarItem;
