import React, { createRef } from 'react';
import ImagesBloc from '../../../blocs/ImagesBloc/ImagesBloc';
import { GrWordpress, IoCogOutline } from 'react-icons/all';
import { BlocBuilder } from 'react-blocbuilder';
import SettingsBox from '../../SettingsBox/SettingsBox';
import SegmentToggle from '../../SegmentToggle/SegmentToggle';
import ImagesState from '../../../blocs/ImagesBloc/ImagesState';
import Button, { ButtonStyle } from '../../buttons/Button';
import { ReactComponent as WeClappLogo } from '../../../images/logos/weclapp-ccolor.svg';
import MessageBox, { MessageBoxType } from '../../MessageBox/MessageBox';
import * as _ from 'lodash';

class ImagesPage extends React.Component<any, any> {
    private bloc: ImagesBloc;
    private articleNrRef: React.RefObject<any> = createRef();

    constructor( props: any ) {
        super( props );
        this.bloc = new ImagesBloc();
    }

    handleSubmit = async ( e: React.MouseEvent<HTMLButtonElement> ) => {
        e.preventDefault();

        let articleNumber = this.articleNrRef.current.value;

        if ( this.bloc.currentState.settingsState.importMode === "batch" ) {
            let articleNumbers = articleNumber.split( '\n' );
            return await this.bloc.batchImportImagesForArticles( articleNumbers, this.bloc.currentState.settingsState.importFrom );
        }

        // Only sync if we have images & at least 1 for main.
        if ( _.isPlainObject( this.bloc.currentState.weClappImages )
             && this.bloc.currentState.hasWeClappImages ) {
            /**
             * If `fetchByMode` is in "ID", use articleNumber (sku) from weClappData.
             */
            if ( this.bloc.currentState.settingsState.fetchByMode === "id"
                 && !_.isUndefined( this.bloc.currentState.weClappData.articleNumber ) ) {
                articleNumber = this.bloc.currentState.weClappData.articleNumber;
            }

            return await this.bloc.importImagesForArticle( articleNumber );
        }

        /** == == == == == == == ==
         * Fetch data from WeClapp
         == == == == == == == == */

        /**
         * If `articleType` is "variantArticle", fetch variantArticle's variants first
         * & fetch their images individually.
         */
        if ( this.bloc.currentState.settingsState.articleType === "variantArticle" ) {
            await this.bloc.loadWeClappVariationArticleImages( articleNumber, this.bloc.currentState.settingsState.fetchByMode );
            return;
        }

        /**
         * If `articleType` is "article", fetch single article's images directly.
         */
        return await this.bloc.loadWeClappImages(
            articleNumber,
            this.bloc.currentState.settingsState.fetchByMode,
        );
    };

    handleLoadWooCommerceImages = async () => {
        let articleNumber = this.articleNrRef.current.value;

        return await this.bloc.loadWooCommerceImages( articleNumber );
    };

    get mainButtonStyle() {
        if ( this.bloc.currentState.settingsState.importMode === 'batch' ) {
            return ButtonStyle.Default;
        }

        if ( !this.bloc.currentState.weClappData ) {
            return ButtonStyle.Secondary;
        }

        if ( this.bloc.currentState.loadingStates?.wooCommerceImages ?? false ) {
            return ButtonStyle.Disabled;
        }

        return ButtonStyle.Default;
    }

    renderMainButtonLabel = () => {
        if ( !this.bloc.currentState.hasWeClappImages
             && this.bloc.currentState.settingsState.importMode === 'single' ) {
            return "Validieren";
        }

        return "Importieren";
    };

    render() {
        return (
            <BlocBuilder
                initialValue={new ImagesState()}
                subject={this.bloc.getStateSubject()}
                builder={( currentState ) => {

                    return (
                        <div>
                            <div className="flex  flex-row">
                                <h2 className="text-3xl  font-bold">Bilder importieren</h2>

                                <div className="ml-auto">
                                    <button className="flex  flex-row  items-center  |  space-x-3  |  cursor-pointer"
                                            onClick={() => this.bloc.setOpenState( 'settings', !( currentState.data.openStates?.settings ?? true ) )}>
                                    <span className="display-inlineblock  |  text-blue-600">
                                        {!( currentState.data.openStates?.settings ?? true ) && "Einstellungen"}
                                        {( currentState.data.openStates?.settings ?? true ) && "Schließen"}
                                    </span>
                                        <IoCogOutline size={30} color={"#3b82f6"}/>
                                    </button>
                                </div>
                            </div>

                            {( currentState.data.openStates?.settings ?? true ) &&
                             <div className="py-3  px-4  |  mt-3  |  bg-gray-200  |  rounded-lg">
                                 <h3 className="text-xl  font-medium  |  mb-4">
                                     Einstellungen
                                 </h3>

                                 <div className="flex  flex-row  |  space-x-5  |  mb-2">
                                     <SettingsBox
                                         title="Artikel-Modus"
                                         desc="Für welche Art von Artikeln sollen die Bilder importiert werden?"
                                         className="border-r  border-gray-300"
                                     >
                                         <SegmentToggle
                                             options={{
                                                 'variantArticle': 'Varianten-Artikel',
                                                 'article':        'Einzel-Artikel',
                                             }}
                                             value={currentState.data.settingsState.articleType} id={'articleType'}
                                             onChange={( e: any, val: string, _: string ) => { this.bloc.setSettings( 'articleType', val ); }}
                                         />
                                     </SettingsBox>

                                     {currentState.data.settingsState.importMode === 'batch' &&
                                      <SettingsBox
                                          title="Bilder-Quelle"
                                          desc="Wechsle zwischen WeClapp & der Stanley/Stella API als Bilder-Quelle."
                                          className="border-r  border-gray-300"
                                      >
                                          <SegmentToggle
                                              options={{
                                                  'weclapp':       'WeClapp',
                                                  'StanleyStella': 'StanleyStella',
                                              }}
                                              value={currentState.data.settingsState.importFrom} id={'importFrom'}
                                              onChange={( e: any, val: string, _: string ) => { this.bloc.setSettings( 'importFrom', val ); }}
                                          />
                                      </SettingsBox>}

                                     {false &&
                                      <SettingsBox
                                          title="Bilder-Import-Modus"
                                          desc="Sollen alle Bilder importiert werden, oder die Bilder manuell ausgewählt werden?"
                                          className="border-r  border-gray-300  |  filter  grayscale  opacity-50  pointer-events-none"
                                      >
                                          <SegmentToggle
                                              options={{
                                                  'auto':   'Alle Bilder',
                                                  'manual': 'Manuell',
                                              }}
                                              value={currentState.data.settingsState.imageImportMode} id={'importMode'}
                                              onChange={( e: any, val: string, _: string ) => { this.bloc.setSettings( 'imageImportMode', val ); }}
                                          />
                                      </SettingsBox>}

                                     <SettingsBox
                                         title="Existierende Bilder"
                                         desc="Was soll bei Produkten geschen die bereits Bilder im Shop-System besitzen?"
                                         className="border-r  border-gray-300"
                                     >
                                         <SegmentToggle
                                             options={{
                                                 'replace': 'Ersetzen',
                                                 'add':     'Anhängen',
                                             }}
                                             value={currentState.data.settingsState.handleExistingImages} id={'handleExistingImages'}
                                             onChange={( e: any, val: string, _: string ) => { this.bloc.setSettings( 'handleExistingImages', val ); }}
                                         />
                                     </SettingsBox>

                                     <SettingsBox
                                         title="Import-Modus"
                                         desc="Wechsle zwischen dem Einzel- & Massen-Import-Modus."
                                         className="border-r  border-gray-300"
                                     >
                                         <SegmentToggle
                                             options={{
                                                 'single': 'Einzeln',
                                                 'batch':  'Batch',
                                             }}
                                             value={currentState.data.settingsState.importMode} id={'importMode'}
                                             onChange={( e: any, val: string, _: string ) => { this.bloc.setSettings( 'importMode', val ); }}
                                         />
                                     </SettingsBox>
                                 </div>
                             </div>}

                            <hr className="my-6"/>

                            <div className="mb-6">
                                <h3 className="text-xl  font-medium  |  mb-4">Gebe die gewünschte Artikel-Nr. ein</h3>

                                <div
                                    className="input-group  |  flex  flex-col  |  px-3  py-2  |  border-2  border-gray-300  |  rounded-md  |  bg-white  |  focus-within:border-gray-400">
                                    <div className="flex  flex-row  |  space-x-4">
                                        <div className="flex  flex-col  |  w-full">
                                            <label className="relative  |  text-sm  font-medium  text-gray-500"
                                                   htmlFor="singleArticleNumber"
                                                   style={{ top: '-1px' }}>Artikel-Nr.</label>

                                            {currentState.data.settingsState.importMode === "single" &&
                                             <input type="text" className="focus:outline-none  font-medium  |  w-full"
                                                    defaultValue={"0105003003"}
                                                    onChange={() => {
                                                        this.bloc.setWeClappImages( null );
                                                        this.bloc.setWooCommerceImages( null );
                                                    }}
                                                    id="singleArticleNumber" ref={this.articleNrRef}/>
                                            }

                                            {currentState.data.settingsState.importMode === "batch" &&
                                             <textarea className="focus:outline-none  font-medium  |  w-full"
                                                       defaultValue={"STSU806C2502X\nAAASU806C2502X"}
                                                       onChange={() => {

                                                       }}
                                                       id="batchArticleNumbers" ref={this.articleNrRef}>
                                         </textarea>
                                            }
                                        </div>

                                        <div className="ml-auto  flex-shrink-0  |  flex  flex-row  space-x-3  items-center  |">
                                            <SegmentToggle
                                                options={{
                                                    'sku': 'SKU',
                                                    'id':  'ID',
                                                }}
                                                value={currentState.data.settingsState?.fetchByMode ?? 'sku'} id={'fetchByMode'}
                                                onChange={( e: any, val: string, _: string ) => { this.bloc.setSettings( 'fetchByMode', val ); }}
                                                className="bg-gray-100"
                                            />

                                            <Button
                                                style={this.mainButtonStyle}
                                                onClick={( e ) => this.handleSubmit( e )}
                                                loading={( currentState.data.loadingStates?.articleNumber === true )}>
                                                {this.renderMainButtonLabel()}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {currentState.data.settingsState.importMode === "single" &&
                             <div className="flex  flex-row  |  space-x-4">
                                 <div className="px-5  py-4  |  rounded-lg  |  bg-gray-200  |  w-1/2">
                                     <h3 className="flex  flex-row  items-center  space-x-3  |  text-xl  font-medium  |  mb-4  |  pb-3  |  border-b-2  border-gray-300">
                                         <WeClappLogo width={20} color={"#12da9d"}/>
                                         <span>WeClapp Bilder</span>
                                     </h3>

                                     {/* Empty State */}
                                     {!currentState.data.weClappImages &&
                                      <div>
                                          <div className="font-medium  text-gray-500  |  mb-4">
                                              Noch keine Bilder geladen
                                          </div>
                                      </div>}

                                     {( currentState.data.weClappImages && !currentState.data.hasWeClappImages ) &&
                                      <div>
                                          <MessageBox>
                                              Keine Bilder gefunden
                                          </MessageBox>
                                      </div>}

                                     {/* Data Table */}
                                     {( currentState.data.weClappImages && currentState.data.hasWeClappImages ) &&
                                      <div className="">
                                          <div className="flex  flex-row  space-x-5  |  font-bold">
                                              <div className="text-left  |  w-36">Bild</div>
                                              <div className="text-left">Value</div>
                                          </div>
                                          <div>
                                              {currentState.data.weClappImages.main.map( ( articleImage: any, index: number ) =>
                                                  <div key={articleImage.id}
                                                       className={`flex  flex-row  |  space-x-5  |  text-xs  |  ${( index % 2 )
                                                                                                                  ? 'bg-gray-100  |  py-2  px-3'
                                                                                                                  : 'py-4  px-3'}  |  rounded`}>
                                                      <div className="font-bold  |  w-36  |  flex-shrink-0">
                                                          <img src={articleImage.src}
                                                               alt={articleImage?.fileName ?? 'Image'}/>
                                                      </div>
                                                      <div className="break-all  |  font-mono">
                                                         <pre
                                                             className="whitespace-pre-wrap">{JSON.stringify( articleImage, null, 2 )}</pre>
                                                      </div>
                                                  </div> )}

                                              <hr className="border-t-2  border-gray-400  m-5"/>

                                              {currentState.data.weClappImages.variations.map( ( variationImages: any, i: number ) =>
                                                  <div key={`${i}-${variationImages}`}>
                                                      {variationImages?.map( ( weClappImage: any, index: number ) => (
                                                          <div key={`${weClappImage.id}-${i}-${index}`}
                                                               className={`flex  flex-row  |  space-x-5  |  text-xs  |  ${( index % 2 )
                                                                                                                          ? 'bg-gray-100  |  py-2  px-3'
                                                                                                                          : 'py-4  px-3'}  |  rounded`}>
                                                              <div className="font-bold  |  w-36  |  flex-shrink-0">
                                                                  <img src={weClappImage.src}
                                                                       alt={weClappImage?.name ?? 'Image'}/>
                                                              </div>
                                                              <div className="break-all  |  font-mono">
                                                                  <pre
                                                                      className="whitespace-pre-wrap">{JSON.stringify( weClappImage, null, 2 )}</pre>
                                                              </div>
                                                          </div> ),
                                                      )}
                                                      <hr className="border-gray-300  m-3"/>
                                                  </div> )
                                              }
                                          </div>
                                      </div>
                                     }
                                 </div>

                                 <div className="px-5  py-4  |  rounded-lg  |  bg-gray-200  |  w-1/2">
                                     <h3 className="flex  flex-row  items-center  space-x-3  |  text-xl  font-medium  |  mb-4  |  pb-3  |  border-b-2  border-gray-300">
                                         <GrWordpress size={20} color={"#1d4ed8"}/>
                                         <span>WooCommerce Bilder</span>
                                     </h3>

                                     {/* Empty State */}
                                     {!currentState.data.wooCommerceImages &&
                                      <div>
                                          <div className="font-medium  text-gray-500  |  mb-4">
                                              Noch keine Bilder geladen
                                          </div>

                                          <Button
                                              style={( currentState.data.currentArticleNumber ) ? ButtonStyle.Default
                                                                                                : ButtonStyle.Disabled}
                                              loading={( currentState.data.loadingStates?.wooCommerceImages === true )}
                                              onClick={( e ) => this.handleLoadWooCommerceImages()}>
                                              Neu laden
                                          </Button>
                                      </div>}

                                     {( currentState.data.wooCommerceImages && !currentState.data.hasWooCommerceImages ) &&
                                      <div>
                                          <MessageBox>
                                              Keine Bilder gefunden
                                          </MessageBox>
                                      </div>}

                                     {/* Data Table */}
                                     {currentState.data.wooCommerceImages &&
                                      <div className="">
                                          {currentState.data.hasWooCommerceImages &&
                                           <div>
                                               <div className="flex  flex-row  space-x-5  |  font-bold">
                                                   <div className="text-left  |  w-36">Bild</div>
                                                   <div className="text-left">Value</div>
                                               </div>

                                               {currentState.data.wooCommerceImages.main.map( ( wooCommerceImage: any, index: number ) =>
                                                   <div key={wooCommerceImage.id}
                                                        className={`flex  flex-row  |  space-x-5  |  text-xs  |  ${( index % 2 )
                                                                                                                   ? 'bg-gray-100  |  py-2  px-3'
                                                                                                                   : 'py-4  px-3'}  |  rounded`}>
                                                       <div className="font-bold  |  w-36  |  flex-shrink-0">
                                                           <img src={wooCommerceImage.src}
                                                                alt={wooCommerceImage?.name ?? 'Image'}/>
                                                       </div>
                                                       <div className="break-all  |  font-mono">
                                                          <pre
                                                              className="whitespace-pre-wrap">{JSON.stringify( wooCommerceImage, null, 2 )}</pre>
                                                       </div>
                                                   </div> )
                                               }

                                               <hr className="border-t-2  border-gray-400  m-5"/>

                                               {currentState.data.wooCommerceImages.variations.map( ( variationImages: any, index: number ) =>
                                                   <div key={variationImages}>
                                                       {variationImages?.map( ( wooCommerceImage: any, index: number ) => (
                                                           <div key={wooCommerceImage.id}
                                                                className={`flex  flex-row  |  space-x-5  |  text-xs  |  ${( index % 2 )
                                                                                                                           ? 'bg-gray-100  |  py-2  px-3'
                                                                                                                           : 'py-4  px-3'}  |  rounded`}>
                                                               <div className="font-bold  |  w-36  |  flex-shrink-0">
                                                                   <img src={wooCommerceImage.src}
                                                                        alt={wooCommerceImage?.name ?? 'Image'}/>
                                                               </div>
                                                               <div className="break-all  |  font-mono">
                                                                  <pre
                                                                      className="whitespace-pre-wrap">{JSON.stringify( wooCommerceImage, null, 2 )}</pre>
                                                               </div>
                                                           </div> ),
                                                       )}
                                                       <hr className="border-gray-300  m-3"/>
                                                   </div> )
                                               }
                                           </div>}

                                          {_.isString( currentState.data.wooCommerceImages ) &&
                                           <MessageBox
                                               type={MessageBoxType.Error}>
                                               {currentState.data.wooCommerceImages}
                                           </MessageBox>}
                                      </div>
                                     }
                                 </div>
                             </div>
                            }
                        </div>
                    );
                }}
            />
        );
    }

}

export default ImagesPage;
