export default class MetaState {
    loadingStates: any                                = {};
    openStates: any                                   = {};
    settingsState: any                                = {};
    genderAttributes: any                             = {};
    variationAttributes: UtilsVariationAttributesType = {
        lastUpdated: "Lade...",
        options:     null,
    };
    brandAttributes: any                              = [];
    skusToTransform: string[]                         = [];
    transformedWeClappIds: string[]                   = [];
    skusToFilter: string[]                            = [];
    filteredSkus: string[]                            = [];
}

export type UtilsVariationAttributesType = {
    lastUpdated: string,
    options: any;
};
