export default class CustomersState {
    loadingStates: any               = {};
    openStates: any                  = {};
    settingsState: CustomersSettings = {
        fetchByMode: "customerNumber",
    };
    currentCustomerNumber: string    = '';
    weClappData: any                 = null;
    wooCommerceData: any             = null;
}

export type CustomersSettingsFetchByMode = "customerNumber" | "id";

export type CustomersSettings = {
    [key: string]: string;
    fetchByMode: CustomersSettingsFetchByMode;
}
