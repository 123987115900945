// @ts-ignore
import { BehaviorSubject } from 'rxjs';
import WeClappService from '../../services/WeClappService';
import WooCommerceService from '../../services/WooCommerceService';
import UtilsState from './UtilsState';
import axios, { AxiosResponse } from 'axios';
import https from 'https';
import _ from 'lodash/fp';
import { toast } from 'react-toastify';

export default class UtilsBloc {
    private readonly stateSubject: BehaviorSubject<UtilsState>;
    private weClapp: WeClappService;
    private wooCommerce: WooCommerceService;

    constructor() {
        this.stateSubject = new BehaviorSubject( new UtilsState() );
        this.weClapp      = new WeClappService();
        this.wooCommerce  = new WooCommerceService();

        this.loadGenderAttributes();
        this.loadVariationAttributesLastUpdates();
        this.loadBrandAttributes();
    }

    loadGenderAttributes = async () => {
        this.setLoadingState( 'loadGenderAttributes', true );

        let result: AxiosResponse = await axios.get(
            WooCommerceService.getCptxEndpointUrl( `utils/getGenderTerms` ),
            {
                httpsAgent: new https.Agent( {
                    rejectUnauthorized: false,
                } ),
            },
        );

        let genderAttributes: any = result?.data;

        this.setGenderAttributes( genderAttributes );

        this.setLoadingState( 'loadGenderAttributes', false );
    };

    updateGenderAttributes = async () => {
        this.setLoadingState( 'updateGenderAttributes', true );

        let genderAttributes: any = await this.wooCommerce.updateGenderAttributes();
        this.setGenderAttributes( genderAttributes );

        this.setLoadingState( 'updateGenderAttributes', false );
    };

    updateVariationAttributes = async () => {
        this.setLoadingState( 'updateVariationAttributes', true );

        let variationAttributes: any = await this.wooCommerce.updateVariantAttributeOptions();
        variationAttributes          = JSON.parse( variationAttributes );

        let now        = Date.now();
        let dateString = new Intl.DateTimeFormat( 'de', {
            year:   'numeric',
            month:  'long',
            day:    'numeric',
            hour:   '2-digit',
            minute: '2-digit',
        } ).format( now );

        this.setVariationOptions( variationAttributes, dateString );

        this.setLoadingState( 'updateVariationAttributes', false );
    };

    loadBrandAttributes = async () => {
        this.setLoadingState( 'updateBrandAttributes', true );

        let result: AxiosResponse = await axios.get(
            WooCommerceService.getCptxEndpointUrl( `utils/getBrandTermAttributes` ),
            {
                httpsAgent: new https.Agent( {
                    rejectUnauthorized: false,
                } ),
            },
        );

        let brandAttributes: any = result?.data;
        this.setBrandAttributes( brandAttributes );

        this.setLoadingState( 'updateBrandAttributes', false );
    };

    updateBrandAttributes = async () => {
        this.setLoadingState( 'updateBrandAttributes', true );

        let brandAttributes: any = await this.wooCommerce.updateBrandAttributes();
        brandAttributes          = brandAttributes.map( ( brand: any ) => brand.name );
        this.setBrandAttributes( brandAttributes );

        this.setLoadingState( 'updateBrandAttributes', false );
    };

    loadVariationAttributes = async () => {
        this.setLoadingState( 'loadVariationAttributes', true );

        let result: AxiosResponse = await axios.get(
            WooCommerceService.getCptxEndpointUrl( `utils/getVariantAttributeOptions` ),
            {
                httpsAgent: new https.Agent( {
                    rejectUnauthorized: false,
                } ),
            },
        );

        let variantAttributeOptions: any = JSON.parse( result?.data );
        this.setVariationOptions( variantAttributeOptions );

        this.setLoadingState( 'loadVariationAttributes', false );
    };

    loadVariationAttributesLastUpdates = async () => {
        this.setLoadingState( 'loadVariationAttributes', true );

        let result: AxiosResponse = await axios.get(
            WooCommerceService.getCptxEndpointUrl( `utils/getVariantAttributeOptionsLastUpdated` ),
            {
                httpsAgent: new https.Agent( {
                    rejectUnauthorized: false,
                } ),
            },
        );

        let variantAttributeOptions: any = result?.data;
        this.setVariationOptions( null, variantAttributeOptions );

        this.setLoadingState( 'loadVariationAttributes', false );
    };

    transformSkusToWeClappIds = async ( skusToTransform: string[] = [] ) => {
        this.setLoadingState( 'transformSkusToWeClappIds', true );

        let SHORTEN_IMPORT                     = false;
        let skuChunks                          = _.chunk( 100, skusToTransform );
        let currChunkIndex                     = 1;
        let totalSkusToTransform: string[]     = [];
        let totalSkusNeedToTransform           = 0;
        let totalSkusTransformed               = 0;
        let totalChunks                        = skuChunks?.length ?? 0;
        let allTransformedWeClappIds: string[] = [];

        for ( let chunk of skuChunks ) {
            if ( SHORTEN_IMPORT && currChunkIndex > 2 ) {
                console.warn( `🛑️ Manually stopped chunk SKU transformation.` );
                break;
            }

            // console.log( `⌛️ Filtering chunk ${currChunkIndex}/${totalChunks}.` );

            let skusAfterFilter = chunk.length;

            // console.info( `Filtered chunk: ${articlesBeforeFilter} => ${articlesAfterFilter}` );

            if ( chunk.length === 0 ) {
                console.log( `❌ Skipping chunk ${currChunkIndex}/${totalChunks} because length = 0.` );
                currChunkIndex++;
                continue;
            }

            totalSkusNeedToTransform += skusAfterFilter;

            // Create string of articleIds from chunk
            let skus             = chunk.map(sku => `"${sku}"`).join( ',' );
            totalSkusToTransform = totalSkusToTransform.concat( skus.split( ',' ) );

            toast.info( `⌛️ Transforming chunk ${currChunkIndex}/${totalChunks}.` );
            console.log( `⌛️ Transforming chunk ${currChunkIndex}/${totalChunks}.` );

            let result: AxiosResponse = await axios.get(
                WooCommerceService.getCptxEndpointUrl( `utils/convertArticleNumberIntoArticleId?skus=${skus}` ),
                {
                    httpsAgent: new https.Agent( {
                        rejectUnauthorized: false,
                    } ),
                } );

            let transformedWeClappIds = result?.data;

            console.log( transformedWeClappIds );
            if ( typeof transformedWeClappIds === "undefined" ) {
                toast.error( `❌ Chunk transformation failed for ${currChunkIndex}/${totalChunks}.` );
                console.log( `❌ Chunk transformation failed for ${currChunkIndex}/${totalChunks}.` );
                currChunkIndex++;
                return;
            }

            totalSkusTransformed += transformedWeClappIds.length;

            toast.success( `♋️ Transformed chunk ${currChunkIndex}/${totalChunks}: ${transformedWeClappIds.length} articles.` );
            console.info( `♋️ Transformed chunk ${currChunkIndex}/${totalChunks}.` );

            allTransformedWeClappIds = allTransformedWeClappIds.concat( transformedWeClappIds );

            currChunkIndex++;
        }

        toast.success( `🟢️ Transformation of ${totalChunks} chunks completed!` );
        console.log( allTransformedWeClappIds );
        this.setTransformedWeClappIds( allTransformedWeClappIds );

        this.setLoadingState( 'transformSkusToWeClappIds', false );
    };

    removeNewTags = async () => {
        this.setLoadingState( 'removeNewTags', true );
        let result: AxiosResponse = await axios.get(
            WooCommerceService.getCptxEndpointUrl( `utils/removeNewTagFromAllProducts` ),
            {
                httpsAgent: new https.Agent( {
                    rejectUnauthorized: false,
                } ),
            } );

        toast.success( `🟢️ Removed NEU-Tag from all articles.` );
        console.log( result?.data );

        this.setLoadingState( 'removeNewTags', false );
    };

    fixBaseProductsWithNoImages = async () => {
        this.setLoadingState( 'fixBaseProductsWithNoImages', true );
        let result: AxiosResponse = await axios.get(
            WooCommerceService.getCptxEndpointUrl( `utils/fixNoMainProductImageWithVariantImages` ),
            {
                httpsAgent: new https.Agent( {
                    rejectUnauthorized: false,
                } ),
            } );
        let res                   = result?.data;
        toast.success( `🟢️ Fixed ${res?.length ?? 0} product with no images.` );
        console.log( res );

        this.setLoadingState( 'fixBaseProductsWithNoImages', false );
    };

    filterSkusWithNoImages = async () => {
        if ( this.currentState.skusToFilter.length <= 0 ) {
            toast.error( `❌ Bitte füge SKUs in das Filter-Feld ein.` );
            return;
        }

        this.setLoadingState( 'filterSkus', true );

        let articleNumberChunks = _.chunk( 100, this.currentState.skusToFilter );
        let currChunkIndex      = 1;
        let totalChunks         = articleNumberChunks?.length ?? 0;

        let filteredArticles: any[] = [];

        for ( let chunk of articleNumberChunks ) {
            if ( chunk.length === 0 ) {
                console.log( `❌ Skipping chunk ${currChunkIndex}/${totalChunks} because length = 0.` );
                currChunkIndex++;
                continue;
            }

            // let skus = chunk.join( ',' );
            let skus = JSON.stringify( chunk );
            console.log( `⌛️ Filtering chunk ${currChunkIndex}/${totalChunks}.` );

            let params = `articleNumber-in=${skus}&active-eq=true&properties=id,articleNumber,articleImages`;

            let result: AxiosResponse = await axios.get(
                WeClappService.getEndpointUrl( `article?${params}` ),
                {
                    httpsAgent: new https.Agent( {
                        rejectUnauthorized: false,
                    } ),
                } );
            let newArticleSkus        = result?.data?.result ?? [];

            console.log( newArticleSkus );
            let remainedArticleSkus = newArticleSkus.filter( ( article: any ) => ( article.articleImages?.length ?? 0 ) > 0 );
            let removedArticleSkus  = newArticleSkus.filter( ( article: any ) => ( article.articleImages?.length ?? 0 ) <= 0 );

            console.log( "Remained", remainedArticleSkus );
            console.log( "Removed", removedArticleSkus );
            if ( typeof newArticleSkus === "undefined" ) {
                toast.error( `❌ Chunk filtering failed for ${currChunkIndex}/${totalChunks}.` );
                console.log( `❌ Chunk filtering failed for ${currChunkIndex}/${totalChunks}.` );
                currChunkIndex++;
                return;
            }

            filteredArticles = _.concat( filteredArticles, remainedArticleSkus );
            toast.success( `🔥 Filtered chunk ${currChunkIndex}/${totalChunks}: ${remainedArticleSkus.length}/${chunk.length} new articles remained.` );
            console.info( `🔥 Filtered chunk ${currChunkIndex}/${totalChunks}: ${remainedArticleSkus.length}/${chunk.length} new articles remained.` );

            currChunkIndex++;
        }

        console.log( `🟢️ Filtering of ${totalChunks} chunks completed!` );
        toast.success( `🟢️ Filtering of ${totalChunks} chunks completed!` );
        let filteredArticleNumbers = filteredArticles.map( ( article: any ) => article.articleNumber );
        this.setFilteredSkus( filteredArticleNumbers );

        this.setLoadingState( 'filterSkus', false );
    };

    filterExistingSkus = async ( keepExisting: boolean = true ) => {
        if ( this.currentState.skusToFilter.length <= 0 ) {
            toast.error( `❌ Bitte füge SKUs in das Filter-Feld ein.` );
            return;
        }

        this.setLoadingState( 'filterSkus', true );

        let articleNumberChunks = _.chunk( 250, this.currentState.skusToFilter );
        let currChunkIndex      = 1;
        let totalChunks         = articleNumberChunks?.length ?? 0;

        let filteredArticleNumbers: any[] = [];

        for ( let chunk of articleNumberChunks ) {
            if ( chunk.length === 0 ) {
                console.log( `❌ Skipping chunk ${currChunkIndex}/${totalChunks} because length = 0.` );
                currChunkIndex++;
                continue;
            }

            let skus = chunk.join( ',' );
            console.log( `⌛️ Filtering chunk ${currChunkIndex}/${totalChunks}.` );

            let result: AxiosResponse = await axios.get(
                WooCommerceService.getCptxEndpointUrl( `utils/filterOutExistingSkus?skus=${skus}` ),
                {
                    httpsAgent: new https.Agent( {
                        rejectUnauthorized: false,
                    } ),
                } );
            let newArticleSkus        = result?.data;

            if ( !keepExisting ) {
                newArticleSkus = _.difference( chunk, newArticleSkus );
            }

            console.log( newArticleSkus );
            if ( typeof newArticleSkus === "undefined" ) {
                toast.error( `❌ Chunk filtering failed for ${currChunkIndex}/${totalChunks}.` );
                console.log( `❌ Chunk filtering failed for ${currChunkIndex}/${totalChunks}.` );
                currChunkIndex++;
                return;
            }

            filteredArticleNumbers = _.concat( filteredArticleNumbers, newArticleSkus );
            toast.success( `🔥 Filtered chunk ${currChunkIndex}/${totalChunks}: ${newArticleSkus.length} articles remained.` );
            console.info( `🔥 Filtered chunk ${currChunkIndex}/${totalChunks}: ${newArticleSkus.length} articles remained.` );

            currChunkIndex++;
        }

        toast.success( `🟢️ Filtering of ${totalChunks} chunks completed!` );
        this.setFilteredSkus( filteredArticleNumbers );

        this.setLoadingState( 'filterSkus', false );
    };

    importSubBrands = async () => {
        this.setLoadingState( 'importSubBrands', true );
        let result: AxiosResponse = await axios.get(
            WooCommerceService.getCptxEndpointUrl( `utils/importSubBrands` ),
            {
                httpsAgent: new https.Agent( {
                    rejectUnauthorized: false,
                } ),
            } );
        let newSubBrands = result?.data;
        toast.success( `🟢️ ${newSubBrands.length} new sub brands imported.` );
        this.setLoadingState( 'importSubBrands', false );
    }

    setSubBrands = async () => {
        this.setLoadingState( 'setSubBrands', true );

        let result: AxiosResponse = await axios.get(
            WooCommerceService.getCptxEndpointUrl( `utils/setSubBrands` ),
            {
                httpsAgent: new https.Agent( {
                    rejectUnauthorized: false,
                } ),
            } );
        let updatedArticles = result?.data;
        toast.success( `🟢️ Sub-Brands for ${updatedArticles.length} articles set.` );
        console.log(updatedArticles);

        this.setLoadingState( 'setSubBrands', false );
    }

    updateBrandCategoryOrders = async () => {
        this.setLoadingState( 'updateBrandCategoryOrders', true );

        let result: AxiosResponse = await axios.get(
            WooCommerceService.getCptxEndpointUrl( `utils/setBrandCategoryOrders` ),
            {
                httpsAgent: new https.Agent( {
                    rejectUnauthorized: false,
                } ),
            } );
        let orderedCategories = result?.data;
        toast.success( `🟢️ Product menu_order updated for ${orderedCategories.length} categories.` );
        console.log(orderedCategories);

        this.setLoadingState( 'updateBrandCategoryOrders', false );
    }

    addSizeGuideLinksToDl = async () => {
        this.setLoadingState( 'addSizeGuideLinksToDl', true );

        let result: AxiosResponse = await axios.get(
            WooCommerceService.getCptxEndpointUrl( `utils/setSizeGuideLinksToDl` ),
            {
                httpsAgent: new https.Agent( {
                    rejectUnauthorized: false,
                } ),
            } );
        let updatedArticles = result?.data;
        toast.success( `🟢️ Size guide links added to ${updatedArticles.length} articles.` );
        console.log(updatedArticles);

        this.setLoadingState( 'addSizeGuideLinksToDl', false );
    }

    setFilterColors = async () => {
        this.setLoadingState( 'setFilterColors', true );
        // repeat sending the request until no entries are returned from the API
        let returnedArticles = [];
        let failSafe = 1;
        do {
            let result: AxiosResponse = await axios.get(
                WooCommerceService.getCptxEndpointUrl( `utils/transformColorAttributeToFilterColor?page=${failSafe}` ),
                {
                    httpsAgent: new https.Agent( {
                        rejectUnauthorized: false,
                    } ),
                } );
            returnedArticles = result?.data;
            toast.success( `🟢️ Set filer colors for ${returnedArticles.length} articles. (Page ${failSafe})` );
            console.log(returnedArticles);
            failSafe++;
            // debugger;
        } while ( returnedArticles.length > 0 && failSafe < 50 );


        this.setLoadingState( 'setFilterColors', false );
    }

    setVariationOptions = ( variationOptions: any = {}, updatedString: string = '' ) => {
        let currentState: UtilsState = this.currentState;

        if ( !_.isNull( variationOptions ) ) {
            currentState.variationAttributes.options = variationOptions;
        }


        if ( !_.isEmpty( updatedString ) ) {
            currentState.variationAttributes.lastUpdated = updatedString;
        }

        this.pushState( currentState );

        return currentState;
    };

    setSkusToTransform = ( skusToTransform: string[] = [] ) => {
        let currentState: UtilsState = this.currentState;

        currentState.skusToTransform = skusToTransform;

        this.pushState( currentState );

        return currentState;
    };

    setTransformedWeClappIds = ( transformedIds: string[] = [] ) => {
        let currentState: UtilsState = this.currentState;

        currentState.transformedWeClappIds = transformedIds;

        this.pushState( currentState );

        return currentState;
    };

    setSkusToFilter = ( skusToFilter: string[] = [] ) => {
        let currentState: UtilsState = this.currentState;

        currentState.skusToFilter = skusToFilter;

        this.pushState( currentState );

        return currentState;
    };

    setFilteredSkus = ( filteredSkus: string[] = [] ) => {
        let currentState: UtilsState = this.currentState;

        currentState.filteredSkus = filteredSkus;

        this.pushState( currentState );

        return currentState;
    };

    setGenderAttributes = ( genderAttributes: any ) => {
        let currentState: UtilsState  = this.currentState;
        currentState.genderAttributes = genderAttributes;
        this.pushState( currentState );

        return currentState;
    };
    setBrandAttributes  = ( brandAttributes: any ) => {
        let currentState: UtilsState = this.currentState;
        currentState.brandAttributes = brandAttributes;
        this.pushState( currentState );

        return currentState;
    };

    setSettings = ( key: string, value: any ) => {
        let currentState: UtilsState    = this.currentState;
        currentState.settingsState[key] = value;
        this.pushState( currentState );

        return currentState;
    };

    setLoadingState = ( key: string, loading: boolean = true ) => {
        let currentState: UtilsState    = this.currentState;
        currentState.loadingStates[key] = loading;
        this.pushState( currentState );

        return currentState;
    };

    setOpenState = ( key: string, loading: boolean = true ) => {
        let currentState: UtilsState = this.currentState;
        currentState.openStates[key] = loading;
        this.pushState( currentState );

        return currentState;
    };

    get currentState() {
        return this.getStateSubject().getValue();
    }

    pushState = ( newState: UtilsState ) => {
        this.getStateSubject().next( newState );
        return newState;
    };

    getStateSubject = () => {
        return this.stateSubject;
    };
}
