import React, { Component, createRef } from 'react';
import UtilsBloc from '../../../blocs/UtilsBloc/UtilsBloc';
import { BsWrench, FiCopy, FiFilter, IoCogOutline, IoTransgender, MdTransform, SiBrandfolder, VscSymbolVariable } from 'react-icons/all';
import { BlocBuilder } from 'react-blocbuilder';
import Button, { ButtonStyle } from '../../buttons/Button';
import UtilsState from '../../../blocs/UtilsBloc/UtilsState';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { github } from 'react-syntax-highlighter/dist/esm/styles/hljs';

class ProductsPage extends Component<any, any> {
    private bloc: UtilsBloc;
    private skusToTransformRef: React.RefObject<any>       = createRef();
    private transformedWeClappIdsRef: React.RefObject<any> = createRef();
    private skusToFilterRef: React.RefObject<any>          = createRef();

    constructor( props: any ) {
        super( props );

        this.bloc = new UtilsBloc();
    }

    render() {
        return (
            <BlocBuilder
                initialValue={new UtilsState()}
                subject={this.bloc.getStateSubject()}
                builder={( currentState ) => {
                    return ( <div>
                            <div className="flex  flex-row">
                                <h2 className="text-3xl  font-bold">Helfer-Funktionen</h2>

                                <div className="ml-auto">
                                    <button className="flex  flex-row  items-center  |  space-x-3  |  cursor-pointer"
                                            onClick={() => this.bloc.setOpenState( 'settings', !( currentState.data.openStates?.settings ?? false ) )}>
                                    <span className="display-inlineblock  |  text-blue-600">
                                        {!( currentState.data.openStates?.settings ?? false ) && "Einstellungen"}
                                        {( currentState.data.openStates?.settings ?? false ) && "Schließen"}
                                    </span>
                                        <IoCogOutline size={30} color={"#3b82f6"}/>
                                    </button>
                                </div>
                            </div>

                            <hr className="my-6"/>

                            <div className="flex  flex-row  flex-wrap  |  space-x-4  -ml-4">
                                <div className="ml-4  px-5  py-4  mb-4  |  rounded-lg  |  bg-gray-200  |  w-1/4">
                                    <h3 className="flex  flex-row  items-center  space-x-3  |  text-xl  font-medium  |  mb-4  |  pb-3  |  border-b-2  border-gray-300">
                                        <IoTransgender size={20}/>
                                        <span>Geschlechter-Attribute</span>
                                    </h3>

                                    <div className="whitespace-pre-wrap  |  font-mono  text-xs  |  rounded-md  overflow-hidden  |  mb-3">
                                        <SyntaxHighlighter language="json" style={github}>
                                            {JSON.stringify( currentState.data?.genderAttributes, null, 2 )}
                                        </SyntaxHighlighter>
                                    </div>

                                    <div className="flex  flex-row  items-center  space-x-3">
                                        <Button
                                            style={ButtonStyle.Default}
                                            onClick={( e ) => this.bloc.updateGenderAttributes()}
                                            loading={( currentState.data.loadingStates?.updateGenderAttributes === true
                                                       || currentState.data.loadingStates?.loadGenderAttributes === true )}
                                        >
                                            Aktualisieren
                                        </Button>
                                    </div>
                                </div>

                                <div className="px-5  py-4  mb-4  |  rounded-lg  |  bg-gray-200  |  w-1/4">
                                    <h3 className="flex  flex-row  items-center  space-x-3  |  text-xl  font-medium  |  mb-4  |  pb-3  |  border-b-2  border-gray-300">
                                        <VscSymbolVariable size={20}/>
                                        <span>Variation-Attributes</span>
                                    </h3>

                                    <div className="flex  flex-col  space-y-1  |  mb-4">
                                        <div className="relative  |  text-sm  font-medium  text-gray-500">
                                            Letzte Aktualisierung
                                        </div>
                                        <div className="font-medium">
                                            {currentState.data.variationAttributes.lastUpdated}
                                        </div>
                                    </div>

                                    {currentState.data.variationAttributes.options &&
                                     <div className="rounded-md  overflow-hidden  |  p-2  |  mb-3">
                                     <pre
                                         className="bg-gray-100  |  whitespace-pre-wrap  |  font-mono  text-xs  |  overflow-auto  |  max-h-80">
                                         {JSON.stringify( currentState.data.variationAttributes.options, null, 2 )}
                                     </pre>
                                     </div>}

                                    <div className="flex  flex-row  items-center  space-x-3">
                                        <Button
                                            style={ButtonStyle.Default}
                                            onClick={( e ) => this.bloc.updateVariationAttributes()}
                                            loading={( currentState.data.loadingStates?.updateVariationAttributes === true )}>
                                            Aktualisieren
                                        </Button>

                                        <Button
                                            style={ButtonStyle.Secondary}
                                            onClick={( e ) => this.bloc.loadVariationAttributes()}
                                            loading={( currentState.data.loadingStates?.loadVariationAttributes === true )}>
                                            Anzeigen
                                        </Button>
                                    </div>
                                </div>

                                <div className="px-5  py-4  mb-4  |  rounded-lg  |  bg-gray-200  |  w-1/4">
                                    <h3 className="flex  flex-row  items-center  space-x-3  |  text-xl  font-medium  |  mb-4  |  pb-3  |  border-b-2  border-gray-300">
                                        <SiBrandfolder size={20}/>
                                        <span>Marken-Attribute</span>
                                    </h3>

                                    {currentState.data.brandAttributes &&
                                     <div className="rounded-md  overflow-hidden  |  p-2  |  mb-3">
                                     <pre
                                         className="bg-gray-100  |  whitespace-pre-wrap  |  font-mono  text-xs  |  overflow-auto  |  max-h-80">
                                         {JSON.stringify( currentState.data.brandAttributes, null, 2 )}
                                     </pre>
                                     </div>}

                                    <div className="flex  flex-row  items-center  space-x-3">
                                        <Button
                                            style={ButtonStyle.Default}
                                            onClick={( e ) => this.bloc.updateBrandAttributes()}
                                            loading={( currentState.data.loadingStates?.updateBrandAttributes === true )}>
                                            Aktualisieren
                                        </Button>
                                    </div>
                                </div>

                                <div className="px-5  py-4  mb-4  |  rounded-lg  |  bg-gray-200  |  w-1/5">
                                    <h3 className="flex  flex-row  items-center  space-x-3  |  text-xl  font-medium  |  mb-4  |  pb-3  |  border-b-2  border-gray-300">
                                        <BsWrench size={20}/>
                                        <span>Hilfs-Aktionen</span>
                                    </h3>

                                    <div className="flex  flex-col  space-y-3">


                                    </div>
                                </div>

                                <div className="px-5  py-4  mb-4  |  rounded-lg  |  bg-gray-200  |  w-1/4">
                                    <h3 className="flex  flex-row  items-center  space-x-3  |  text-xl  font-medium  |  mb-4  |  pb-3  |  border-b-2  border-gray-300">
                                        <MdTransform size={20}/>
                                        <span>SKU zu WeClapp ID</span>
                                    </h3>

                                    <div
                                        className="input-group  |  flex  flex-col  |  px-3  py-2  |  border-2  border-gray-300  |  rounded-md  |  bg-white  |  focus-within:border-gray-400">
                                    <textarea className="focus:outline-none  font-medium  |  w-full"
                                              defaultValue={"STSU806C2502X\nAAASU806C2502X"}
                                              onChange={() => {
                                                  this.bloc.setTransformedWeClappIds( [] );
                                              }}
                                              id="transformSkus" ref={this.skusToTransformRef}>
                                         </textarea>
                                    </div>

                                    <div className="mb-3"/>

                                    <div className="flex  flex-row  items-center  space-x-3">
                                        <Button
                                            style={ButtonStyle.Default}
                                            onClick={( e ) => {
                                                let skusToTransform = this.skusToTransformRef.current.value.split( '\n' );
                                                this.bloc.setSkusToTransform( skusToTransform );
                                                this.bloc.transformSkusToWeClappIds( skusToTransform );
                                            }}
                                            loading={( currentState.data.loadingStates?.transformSkusToWeClappIds === true )}>
                                            Transformieren
                                        </Button>
                                    </div>
                                </div>

                                <div className="px-5  py-4  mb-4  |  rounded-lg  |  bg-gray-200  |  w-1/4">
                                    <h3 className="flex  flex-row  items-center  |  text-xl  font-medium  |  mb-4  |  pb-3  |  border-b-2  border-gray-300">
                                        <div className="flex  flex-row  items-center  space-x-3">
                                            <MdTransform size={20} className={`flex-shrink-0`}/>
                                            <span>Transformierte WeClapp IDs</span>
                                        </div>

                                        <div className="ml-auto  flex  flex-row  items-center  space-x-5">
                                            <div className={`cursor-pointer  text-blue-600  hover:text-blue-500
                                                        |  flex  flex-row  items-center  text-sm  space-x-2`}
                                                 onClick={() => navigator.clipboard.writeText( currentState.data.transformedWeClappIds.join( '\n' ) )}>
                                                <FiCopy size={20}/>
                                                <span>Kopieren</span>
                                            </div>
                                        </div>
                                    </h3>

                                    <pre
                                        className="bg-gray-100  |  whitespace-pre-wrap  |  font-mono  text-xs  |  overflow-auto  |  max-h-80"
                                        style={{
                                            columnCount: 2,
                                        }}>
                                         {currentState.data.transformedWeClappIds.join( '\n' )}
                                    </pre>
                                </div>

                                <div className="px-5  py-4  mb-4  |  rounded-lg  |  bg-gray-200  |  w-1/3  |  w-1/4">
                                    <h3 className="flex  flex-row  items-center  |  text-xl  font-medium  |  mb-4  |  pb-3  |  border-b-2  border-gray-300">
                                        <div className="flex  flex-row  items-center  space-x-3">
                                            <FiFilter size={20} className={`flex-shrink-0`}/>
                                            <span>SKUs filtern ({this.skusToFilterRef.current?.value?.split( '\n' )?.length ?? 0})</span>
                                        </div>
                                    </h3>

                                    <div
                                        className="input-group  |  flex  flex-col  |  px-3  py-2  |  border-2  border-gray-300  |  rounded-md  |  bg-white  |  focus-within:border-gray-400">
                                    <textarea className="focus:outline-none  font-medium  |  w-full"
                                              defaultValue={"STSU806C2502X\nAAASU806C2502X"}
                                              onChange={() => {
                                                  let skusToFilter = this.skusToFilterRef.current.value.split( '\n' );
                                                  this.bloc.setSkusToFilter( skusToFilter );
                                              }}
                                              id="skusToFilter" ref={this.skusToFilterRef}>
                                         </textarea>
                                    </div>

                                    <div className="mb-3"/>

                                    <div className="flex  flex-col  items-stretch  space-y-3">
                                        <Button
                                            style={ButtonStyle.Default}
                                            onClick={( e ) => {
                                                let skusToFilter = this.skusToFilterRef.current.value.split( '\n' );
                                                this.bloc.setSkusToFilter( skusToFilter );
                                                this.bloc.filterExistingSkus();
                                            }}
                                            loading={( currentState.data.loadingStates?.filterSkus === true )}>
                                            Existierende entfernen
                                        </Button>

                                        <Button
                                            style={ButtonStyle.Default}
                                            onClick={( e ) => {
                                                let skusToFilter = this.skusToFilterRef.current.value.split( '\n' );
                                                this.bloc.setSkusToFilter( skusToFilter );
                                                this.bloc.filterExistingSkus(false);
                                            }}
                                            loading={( currentState.data.loadingStates?.filterSkus === true )}>
                                            Nur Existierende behalten
                                        </Button>

                                        <Button
                                            style={ButtonStyle.Default}
                                            onClick={( e ) => {
                                                let skusToFilter = this.skusToFilterRef.current.value.split( '\n' );
                                                this.bloc.setSkusToFilter( skusToFilter );
                                                this.bloc.filterSkusWithNoImages();
                                            }}
                                            loading={( currentState.data.loadingStates?.filterSkus === true )}>
                                            Ohne Bilder entfernen
                                        </Button>
                                    </div>
                                </div>

                                <div className="px-5  py-4  mb-4  |  rounded-lg  |  bg-gray-200  |  w-1/5  flex-grow">
                                    <h3 className="flex  flex-row  items-center  |  text-xl  font-medium  |  mb-4  |  pb-3  |  border-b-2  border-gray-300">
                                        <div className="flex  flex-row  items-center  space-x-3">
                                            <FiFilter size={20} className={`flex-shrink-0`}/>
                                            <span>Gefilterte SKUs ({currentState.data.filteredSkus.length ?? 0})</span>
                                        </div>

                                        <div className="ml-auto  flex  flex-row  items-center  space-x-5">
                                            <div className={`cursor-pointer  text-blue-600  hover:text-blue-500
                                                        |  flex  flex-row  items-center  text-sm  space-x-2`}
                                                 onClick={() => navigator.clipboard.writeText( currentState.data.filteredSkus.join( '\n' ) )}>
                                                <FiCopy size={20}/>
                                                <span>Kopieren</span>
                                            </div>
                                        </div>
                                    </h3>

                                    <div
                                        className="input-group  |  flex  flex-col  |  px-3  py-2  |  border-2  border-gray-300  |  rounded-md  |  bg-white  |  focus-within:border-gray-400">
                                    <pre
                                        className="bg-gray-100  |  whitespace-pre-wrap  |  font-mono  text-xs  |  overflow-auto  |  max-h-80">
                                        {currentState.data.filteredSkus.join( '\n' )}
                                    </pre>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                }}
            />
        );
    }
}

export default ProductsPage;
