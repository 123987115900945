import React from 'react';
import classNames from 'classnames/bind';

export enum MessageBoxType {
    Default,
    Alert,
    Info,
    Success,
    Error,
}

export type MessageBoxProps = {
    type?: MessageBoxType,
    children?: any,
}

const defaultProps: MessageBoxProps = {
    type:     MessageBoxType.Default,
    children: undefined,
};

function MessageBox( props: MessageBoxProps ) {

    let boxClasses = classNames( {
        'font-lg  font-medium  |':   true,
        'px-5  py-3  |':             true,
        'rounded-md  |':             true,
        'bg-gray-300  |':            ( props.type === MessageBoxType.Default ),
        'bg-red-600  text-white  |': ( props.type === MessageBoxType.Error ),
    } );

    return (
        <div className={boxClasses}>
            {props.children}
        </div>
    );
}

MessageBox.defaultProps = defaultProps;

export default MessageBox;
