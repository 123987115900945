import React from 'react';

export interface SettingsBoxProps {
    title?: string | undefined,
    desc?: string | undefined,
    children?: any | undefined,
    className?: string | undefined,
}

const defaultProps: SettingsBoxProps = {
    title:     undefined,
    desc:      undefined,
    children:  undefined,
    className: '',
};

function SettingsBox( props: SettingsBoxProps = defaultProps ) {
    return ( <div
        className={`w-1/4  |  flex  flex-col  items-start  space-y-2  |  py-2  px-2  |  ${props.className} |`}>

        {props.title &&
         <div className="font-medium text-gray-700">
             {props.title}
         </div>}

        {props.desc &&
         <p className="text-sm  text-gray-500">
             {props.desc}
         </p>}

        {props.children && <div>
            {props.children}
        </div>}
    </div> );
}

export default SettingsBox;
