// @ts-ignore
import { BehaviorSubject } from 'rxjs';
import ProductsState, {
    ProductSettingsArticleType,
    ProductSettingsFetchByMode,
    ProductSettingsHandleExistingProducts,
} from './ProductsState';
import WeClappService from '../../services/WeClappService';
import WooCommerceService from '../../services/WooCommerceService';
import _ from 'lodash/fp';
import { toast } from 'react-toastify';
import axios, { AxiosResponse } from 'axios';
import https from 'https';

export default class ProductsBloc {
    private readonly stateSubject: BehaviorSubject<ProductsState>;
    private weClapp: WeClappService;
    private wooCommerce: WooCommerceService;

    constructor() {
        this.stateSubject = new BehaviorSubject( new ProductsState() );
        this.weClapp      = new WeClappService();
        this.wooCommerce  = new WooCommerceService();
    }

    loadArticleData = async ( articleNumber: string, fetchBy: ProductSettingsFetchByMode = "sku", articleType: ProductSettingsArticleType = "variantArticle" ) => {
        this.setCurrentArticleNumber( articleNumber );
        this.setLoadingState( 'articleNumber', true );

        let res: any;

        if ( articleType === "article" ) {
            res = await this.weClapp.loadArticle( articleNumber, fetchBy );
        }

        if ( articleType === "variantArticle" ) {
            res = await this.weClapp.loadVariantArticle( articleNumber, fetchBy );
        }

        res = res?.result;

        this.setWeClappData( res[0] ?? [] );
        this.setLoadingState( 'articleNumber', false );
        return res[0];
    };

    deleteArticle = async ( weClappData: any | undefined ) => {
        if ( _.isUndefined( weClappData ) ) {
            weClappData = this.currentState.weClappData;
        }

        this.setLoadingState( 'articleNumber', true );

        await this.wooCommerce.deleteProduct( this.currentState.wooCommerceData.id );

        this.setLoadingState( 'articleNumber', false );
    };

    syncArticle = async ( weClappData: any | undefined ) => {
        if ( _.isUndefined( weClappData ) ) {
            weClappData = this.currentState.weClappData;
        }

        this.setLoadingState( 'articleNumber', true );
        let wooCommerceData = WooCommerceService.transformWeClappToWooCommerceData( weClappData );

        let product: any | undefined;
        /**
         * WooCommerce has found a product & "SKIP" is selected.
         * => Skip sync!
         */
        if ( _.isObject( this.currentState.wooCommerceData ) && this.currentState.settingsState.handleExistingProducts === 'skip' ) {
            toast.error( "Produkt existiert bereits – Übersprungen!" );
        }


        /**
         * WooCommerce has found a product & "UPDATE" is selected.
         * => Overwrite product!
         */
        if ( _.isObject( this.currentState.wooCommerceData ) && this.currentState.settingsState.handleExistingProducts === 'update' ) {
            // wooCommerceData.short_description = "updated product";

            // @ts-ignore
            product = await this.wooCommerce.updateProduct( this.currentState.wooCommerceData.id, wooCommerceData );
        }

        /**
         * WooCommerce hasn't found a product.
         * => Sync to WooCommerce!
         */
        if ( !_.isObject( this.currentState.wooCommerceData ) ) {

            // Single Article
            if ( !_.isUndefined( weClappData.articleNumber ) ) {
                product = await this.wooCommerce.postProduct( wooCommerceData );
            }

            // Variant Article
            if ( !_.isUndefined( weClappData.variantArticleNumber ) ) {
                product = await this.wooCommerce.postVariantArticle( weClappData.id );
            }

        }

        // If no action was taken (product == undefined)
        // Only update wooCommerceData if action was taken.
        if ( !_.isUndefined( product ) ) {
            this.setWooCommerceData( product );
        }

        this.setLoadingState( 'articleNumber', false );

        return product;
    };

    deleteBatchArticles = async ( articleNumbers: string[] ) => {
        this.setLoadingState( 'articleNumber', true );

        let SHORTEN_IMPORT                     = false;
        let articleNumberChunks                = _.chunk( 250, articleNumbers );
        let currChunkIndex                     = 1;
        let totalArticleSkusToDelete: string[] = [];
        let totalArticlesToDeleted             = 0;
        let totalArticlesDeleted               = 0;
        let totalChunks                        = articleNumberChunks?.length ?? 0;

        for ( let chunk of articleNumberChunks ) {
            if ( SHORTEN_IMPORT && currChunkIndex > 2 ) {
                console.warn( `🛑️ Manually stopped chunk delete.` );
                break;
            }

            // console.log( `⌛️ Filtering chunk ${currChunkIndex}/${totalChunks}.` );

            let articlesAfterFilter = chunk.length;

            // console.info( `Filtered chunk: ${articlesBeforeFilter} => ${articlesAfterFilter}` );

            if ( chunk.length === 0 ) {
                console.log( `❌ Skipping chunk ${currChunkIndex}/${totalChunks} because length = 0.` );
                currChunkIndex++;
                continue;
            }

            totalArticlesToDeleted += articlesAfterFilter;

            // Create string of articleIds from chunk
            let skus             = chunk.map(sku => `"${sku}"`).join( ',' );
            totalArticleSkusToDelete = totalArticleSkusToDelete.concat( skus.split( ',' ) );

            toast.info( `⌛️ Deleting chunk ${currChunkIndex}/${totalChunks}.` );
            console.log( `⌛️ Deleting chunk ${currChunkIndex}/${totalChunks}.` );

            let result: AxiosResponse = await axios.get(
                WooCommerceService.getCptxEndpointUrl( `products/batchDelete?skus=${skus}` ),
                {
                    httpsAgent: new https.Agent( {
                        rejectUnauthorized: false,
                    } ),
                } );

            let deleteProductIds = result?.data;

            console.log( deleteProductIds );
            if ( typeof deleteProductIds === "undefined" ) {
                toast.error( `❌ Chunk delete failed for ${currChunkIndex}/${totalChunks}.` );
                console.log( `❌ Chunk delete failed for ${currChunkIndex}/${totalChunks}.` );
                currChunkIndex++;
                return;
            }

            totalArticlesDeleted += deleteProductIds.length;

            toast.success( `🔥 Deleted chunk ${currChunkIndex}/${totalChunks}: ${deleteProductIds.length ?? "0"} articles.` );
            console.info( `🔥Deleted chunk ${currChunkIndex}/${totalChunks}.` );

            currChunkIndex++;
        }

        toast.success( `🟢️ Deletion of ${totalChunks} chunks completed!` );

        this.setLoadingState( 'articleNumber', false );
    };

    syncBatchArticles = async ( articleNumbers: string[], syncMode: ProductSettingsHandleExistingProducts = 'skip' ) => {
        this.setLoadingState( 'articleNumber', true );

        let SHORTEN_IMPORT                    = false;
        let articleNumberChunks               = _.chunk( 200, articleNumbers );
        let currChunkIndex                    = 1;
        let totalArticleIdsToImport: string[] = [];
        let totalArticleNrsToImport: string[] = [];
        let totalShopArticlesToImport         = 0;
        let totalVariantArticlesImported      = 0;
        let totalShopArticlesImported         = 0;
        let totalChunks                       = articleNumberChunks?.length ?? 0;

        for ( let chunk of articleNumberChunks ) {
            if ( SHORTEN_IMPORT && currChunkIndex > 2 ) {
                console.warn( `🛑️ Manually stopped chunk import.` );
                break;
            }

            // console.log( `⌛️ Filtering chunk ${currChunkIndex}/${totalChunks}.` );

            let articlesBeforeFilter = chunk.length;

            // // Filter out articles without images
            // chunk = chunk.filter( ( article ) => {
            //     return ( ( article.articleImages?.length ?? 0 ) > 0 );
            // } );

            let articlesAfterFilter = chunk.length;

            // console.info( `Filtered chunk: ${articlesBeforeFilter} => ${articlesAfterFilter}` );

            if ( chunk.length === 0 ) {
                console.log( `❌ Skipping chunk ${currChunkIndex}/${totalChunks} because length = 0.` );
                currChunkIndex++;
                continue;
            }

            totalShopArticlesToImport += articlesAfterFilter;

            // Create string of articleIds from chunk
            let articleIds          = chunk.join( ',' );
            totalArticleIdsToImport = totalArticleIdsToImport.concat( articleIds.split( ',' ) );

            toast.info( `⌛️ Importing chunk ${currChunkIndex}/${totalChunks}.` );
            console.log( `⌛️ Importing chunk ${currChunkIndex}/${totalChunks}.` );

            let result: AxiosResponse = await axios.get(
                WooCommerceService.getCptxEndpointUrl( `utils/importVariantArticles?articleIds=${articleIds}&updateExisting=${syncMode === 'update'
                                                                                                                              ? 'true'
                                                                                                                              : 'false'}` ),
                {
                    httpsAgent: new https.Agent( {
                        rejectUnauthorized: false,
                    } ),
                } );

            let variantArticlesData = result?.data;

            console.log( variantArticlesData );
            if ( typeof variantArticlesData === "undefined" ) {
                toast.error( `❌ Chunk import failed for ${currChunkIndex}/${totalChunks}.` );
                console.log( `❌ Chunk import failed for ${currChunkIndex}/${totalChunks}.` );
                currChunkIndex++;
                return;
            }

            let importedVariantArticles    = variantArticlesData.length;
            let totalImportedChunkArticles = 0;

            for ( let importedVariantArticle of variantArticlesData ) {
                totalImportedChunkArticles += importedVariantArticle?.variants?.length ?? 0;
            }

            totalVariantArticlesImported += importedVariantArticles;
            totalShopArticlesImported += totalImportedChunkArticles;

            toast.success( `🟢️ Imported chunk ${currChunkIndex}/${totalChunks}: ${importedVariantArticles} variantArticles  (${totalImportedChunkArticles} articles).` );
            console.info( `🟢️ Imported chunk ${currChunkIndex}/${totalChunks}.` );
            console.log( `ℹ️️ Synced ${importedVariantArticles} variantArticles (${totalImportedChunkArticles} articles).` );
            console.debug( variantArticlesData );

            currChunkIndex++;
        }

        toast.success( `🟢️ Sync of ${totalChunks} chunks completed!` );

        // toast.success( `✅ Total articleIds to import: ${totalArticleIdsToImport.length ?? 0}` );
        // console.log( `✅ Total articleIds to import: ${totalArticleIdsToImport.length ?? 0}` );
        // // console.log(totalArticleIdsToImport);
        // console.log( JSON.stringify( totalArticleIdsToImport ) );
        // console.log( JSON.stringify( totalArticleNrsToImport ) );

        this.setLoadingState( 'articleNumber', false );
    };

    loadWooCommerceProduct = async ( articleNumber: string ) => {
        this.setLoadingState( 'wooCommerceData', true );
        let wooCommerceData = await this.wooCommerce.loadArticle( articleNumber );

        this.setWooCommerceData( wooCommerceData[0] );

        if ( wooCommerceData.length <= 0 ) {
            this.setWooCommerceData( 'Produkt ist nicht in WooCommerce vorhanden.' );
        }

        this.setLoadingState( 'wooCommerceData', false );

        return wooCommerceData[0];
    };

    setSettings = ( key: string, value: any ) => {
        let currentState: ProductsState = this.currentState;
        currentState.settingsState[key] = value;
        this.pushState( currentState );

        return currentState;
    };

    setLoadingState = ( key: string, loading: boolean = true ) => {
        let currentState: ProductsState = this.currentState;
        currentState.loadingStates[key] = loading;
        this.pushState( currentState );

        return currentState;
    };

    setOpenState = ( key: string, loading: boolean = true ) => {
        let currentState: ProductsState = this.currentState;
        currentState.openStates[key]    = loading;
        this.pushState( currentState );

        return currentState;
    };

    setCurrentArticleNumber = ( articleNumber: string ) => {
        let currentState: ProductsState   = this.currentState;
        currentState.currentArticleNumber = articleNumber;
        this.pushState( currentState );

        return currentState;
    };

    setWeClappData = ( weClappData: any ) => {
        let currentState: ProductsState = this.currentState;
        currentState.weClappData        = weClappData;
        this.pushState( currentState );

        return currentState;
    };

    setWooCommerceData = ( wooCommerceData: any ) => {
        let currentState: ProductsState = this.currentState;
        currentState.wooCommerceData    = wooCommerceData;
        this.pushState( currentState );

        return currentState;
    };

    get currentState() {
        return this.getStateSubject().getValue();
    }

    pushState = ( newState: ProductsState ) => {
        this.getStateSubject().next( newState );
        return newState;
    };

    getStateSubject = () => {
        return this.stateSubject;
    };
}
