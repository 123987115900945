// @ts-ignore
import { BehaviorSubject } from 'rxjs';
import WeClappService from '../../services/WeClappService';
import WooCommerceService from '../../services/WooCommerceService';
import ImagesState, { ProductImagesType } from './ImagesState';
import { ProductSettingsFetchByMode } from '../ProductsBloc/ProductsState';
import _ from 'lodash';
import { toast } from 'react-toastify';

export default class ImagesBloc {
    private readonly stateSubject: BehaviorSubject<ImagesState>;
    private weClapp: WeClappService;
    private wooCommerce: WooCommerceService;


    constructor() {
        this.stateSubject = new BehaviorSubject( new ImagesState() );
        this.weClapp      = new WeClappService();
        this.wooCommerce  = new WooCommerceService();
    }

    generateArticleImages = ( article: any ) => {
        let articleImages = article.articleImages;

        articleImages.map( ( image: any ) => {
            let params: string   = `articleImageId=${image.id}&scaleWidth=1900&scaleHeight=2000`;
            let imageUrl: string = WeClappService.getEndpointUrl( `article/id/${article.id}/downloadArticleImage?${params}` );

            image.src = imageUrl;
            return image;
        } );

        return articleImages;
    };

    loadWeClappImages = async ( articleNumber: string, fetchBy: ProductSettingsFetchByMode = "sku" ) => {
        this.setCurrentArticleNumber( articleNumber );
        this.setLoadingState( 'articleNumber', true );

        let res: any = await this.weClapp.loadArticle( articleNumber, fetchBy );

        res = res?.result;

        // If no article found, return.
        if ( ( res?.length ?? 0 ) <= 0 ) {
            toast.error( "Artikel nicht gefunden – Abgebrochen!" );
            return res;
        }

        let article = res[0];
        this.setWeClappData( article );

        let articleImages = this.generateArticleImages( article );

        this.setWeClappImages( {
            main:       articleImages,
            variations: [],
        } );
        this.setLoadingState( 'articleNumber', false );

        return articleImages;
    };

    loadWeClappVariationArticleImages = async ( articleNumber: string, fetchBy: ProductSettingsFetchByMode = "sku" ) => {
        this.setCurrentArticleNumber( articleNumber );
        this.setLoadingState( 'articleNumber', true );

        let res: any = await this.weClapp.loadVariantArticle( articleNumber, fetchBy, 'includeReferencedEntities=variants.articleId' );

        if ( _.isArray( res?.result ) && ( res?.result?.length ?? 0 ) <= 0 ) {
            toast.error( "Varianten Artikel nicht gefunden – Abgebrochen!" );
            this.setLoadingState( 'articleNumber', false );
            return;
        }

        let referencedEntities: Array<any> = res?.referencedEntities?.article ?? [];

        // Index `referencedEntities` by their articleId, for easier access.
        let referencedEntities_mapped: any = {};
        for ( let refArticle of referencedEntities ) {
            referencedEntities_mapped[refArticle.id] = refArticle;
        }

        let variantArticle: any  = res?.result[0];
        let variants: Array<any> = variantArticle?.variants;

        // Generate imageUrls for main variant (first variant)
        let mainArticle: any       = referencedEntities_mapped[variants[0].articleId];
        let mainImages: Array<any> = this.generateArticleImages( mainArticle );

        // Remove first variant from variants array, because it's used as `mainArticle`
        // variants.shift();

        // Generate imageUrls for all other variants
        let variantImages: Array<Array<any>> = variants?.map( ( variant ) => {
            let variantArticle = referencedEntities_mapped[variant.articleId];
            return this.generateArticleImages( variantArticle );
        } );

        this.setWeClappImages( {
            main:       mainImages,
            variations: variantImages,
        } );

        this.setLoadingState( 'articleNumber', false );
    };

    loadWooCommerceImages = async ( articleNumber: string ) => {
        this.setLoadingState( 'wooCommerceImages', true );

        let res: any = await this.wooCommerce.loadArticle( articleNumber );

        // If no article found, return.
        if ( ( res?.length ?? 0 ) <= 0 ) {
            this.setLoadingState( 'wooCommerceImages', false );
            return res;
        }

        let product = res[0];
        let images  = product.images;

        images.map( ( image: any ) => {
            image.src = image.src.replace( 'https', 'http' );
            return image;
        } );

        this.setWooCommerceImages( {
            main:       images,
            variations: [],
        } );
        this.setLoadingState( 'wooCommerceImages', false );

        return images;
    };

    batchImportImagesForArticles = async ( productIds: string[], brand: string = 'weclapp' ) => {
        this.setLoadingState( 'articleNumber', true );

        let i = 0;
        for (let productId of productIds) {
            i++;
            let res: any = await this.wooCommerce.importImagesForBrandArticle( productId, brand, (this.currentState.settingsState.handleExistingImages === "replace") );
            let importedImages = res;
            toast.success( `🟢️ Imported images for product ${productId} for ${importedImages.variations?.length} variations! (${i}/${productIds.length})` );
            console.log( `🟢️ Imported images for product ${productId} for ${importedImages.variations?.length} variations! (${i}/${productIds.length})` );
            console.log( importedImages );
        }

        this.setLoadingState( 'articleNumber', false );
    };

    importImagesForArticle = async ( articleNumber: string ) => {
        this.setLoadingState( 'articleNumber', true );

        let res: any = await this.wooCommerce.importImagesForArticle( articleNumber );

        let data: ProductImagesType = {
            main:       [],
            variations: [],
        };
        // If no article found, return.
        if ( !_.isPlainObject( res ) ) {
            this.setLoadingState( 'articleNumber', false );
            return res;
        }

        data.main = res.main.images?.map( ( image: any ) => {
            image.src = image.src.replace( 'https', 'http' );
            delete image.alt;
            return image;
        } ) ?? [];

        data.variations = res.variations.map( ( variation: any ) => {
            variation.images = variation.images?.map( ( image: any ) => {
                image.src = image.src.replace( 'https', 'http' );
                delete image.alt;
                return image;
            } );

            return variation.images;
        } ) ?? [];

        this.setWooCommerceImages( data );
        this.setLoadingState( 'articleNumber', false );
        return res;
    };

    setWeClappData = ( weClappData: any ) => {
        let currentState: ImagesState = this.currentState;
        currentState.weClappData      = weClappData;
        this.pushState( currentState );

        return currentState;
    };

    setWeClappImages = ( weClappImages: ProductImagesType ) => {
        let currentState: ImagesState = this.currentState;
        currentState.weClappImages    = weClappImages;
        this.pushState( currentState );

        return currentState;
    };

    setWooCommerceImages = ( wooCommerceImages: ProductImagesType ) => {
        let currentState: ImagesState  = this.currentState;
        currentState.wooCommerceImages = wooCommerceImages;
        this.pushState( currentState );

        return currentState;
    };


    setSettings = ( key: string, value: any ) => {
        let currentState: ImagesState   = this.currentState;
        currentState.settingsState[key] = value;
        this.pushState( currentState );

        return currentState;
    };

    setLoadingState = ( key: string, loading: boolean = true ) => {
        let currentState: ImagesState   = this.currentState;
        currentState.loadingStates[key] = loading;
        this.pushState( currentState );

        return currentState;
    };

    setOpenState = ( key: string, loading: boolean = true ) => {
        let currentState: ImagesState = this.currentState;
        currentState.openStates[key]  = loading;
        this.pushState( currentState );

        return currentState;
    };

    setCurrentArticleNumber = ( articleNumber: string ) => {
        let currentState: ImagesState     = this.currentState;
        currentState.currentArticleNumber = articleNumber;
        this.pushState( currentState );

        return currentState;
    };

    get currentState() {
        return this.getStateSubject().getValue();
    }

    pushState = ( newState: ImagesState ) => {
        this.getStateSubject().next( newState );
        return newState;
    };

    getStateSubject = () => {
        return this.stateSubject;
    };
}
