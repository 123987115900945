import { ProductSettingsFetchByMode } from '../ProductsBloc/ProductsState';

export default class BrandsState {
    loadingStates: any                = {};
    openStates: any                   = {};
    settingsState: BrandsSettings     = {
        fetchActive:     'active',
        loadProp:        'id',
        availableInShop: 'availableInShop',
        loadByProp:      'manufacturer',
    };
    manufacturers: ManufacturerData[] = [];
    articles: any[]                   = [];
}

export type ManufacturerData = {
    name: string,
    id: string,
}

export type BrandsSettings = {
    [key: string]: string;
    fetchActive: BrandSettingsFetchActive;
    loadProp: ProductSettingsFetchByMode;
    availableInShop: BrandSettingsAvailableInShop;
    loadByProp: BrandSettingsLoadByProp;
}

export type BrandSettingsFetchActive = "active" | "inactive";
export type BrandSettingsAvailableInShop = "availableInShop" | "all";
export type BrandSettingsLoadByProp = "manufacturer" | "brand";
