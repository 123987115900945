import React from 'react';
import { RiBuilding4Line, RiCodeBoxLine, RiContactsBookLine, RiImage2Line, RiPencilRuler2Line, RiTShirtLine } from 'react-icons/all';
import SidebarItem from './SidebarItem';
import logo from '../../images/logomark-ccolor.svg';
import { Link, useLocation } from 'react-router-dom';

function Sidebar() {
    let location = useLocation();

    return (
        <aside className={"sidebar  |  w-auto  |  flex-shrink-0"}>
            <Link to="/" className={"flex  flex-col  |  items-start  |  py-4  px-4"}>
                <img src={logo}
                     className={"w-10  h-10  |  mb-2"}
                     alt={"CorpoTex Logo"}/>
                <span className="text-3xl  font-extrabold">
                    WeClapp<br/>
                    Sync&#x2011;Console
                </span>
            </Link>

            <ul className="sidebar-menu  |  flex  flex-col  items-stretch">
                <SidebarItem route="/products" label={"Produkte"} icon={<RiTShirtLine size={20}/>}
                             active={( location.pathname === "/products" )}/>
                <SidebarItem route="/images" label={"Bilder"} icon={<RiImage2Line size={20}/>}
                             active={( location.pathname === "/images" )}/>
                <SidebarItem route="/customers" label={"Kunden"} icon={<RiContactsBookLine size={20}/>}
                             active={( location.pathname === "/customers" )}/>
                <SidebarItem route="/brands" label={"Marken"} icon={<RiBuilding4Line size={20}/>}
                             active={( location.pathname === "/brands" )}/>
                <SidebarItem route="/meta" label={"Meta"} icon={<RiCodeBoxLine size={20}/>}
                             active={( location.pathname === "/meta" )}/>
                <SidebarItem route="/utils" label={"Utilities"} icon={<RiPencilRuler2Line size={20}/>}
                             active={( location.pathname === "/utils" )}/>
            </ul>
        </aside>
    );
}

export default Sidebar;
