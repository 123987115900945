import React, { Component, createRef } from 'react';
import { BsWrench, FiCopy, FiFilter, FiTag, HiOutlineTag, IoCogOutline, IoTransgender, MdTransform,
    RiFile2Line,
    RiGitBranchLine, RiPantoneLine, SiBrandfolder, VscSymbolVariable } from 'react-icons/all';
import { BlocBuilder } from 'react-blocbuilder';
import Button, { ButtonStyle } from '../../buttons/Button';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { github } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import MetaState from '../../../blocs/MetaBloc/MetaState';
import MetaBloc from '../../../blocs/MetaBloc/MetaBloc';

class MetaPage extends Component<any, any> {
    private bloc: MetaBloc;

    constructor( props: any ) {
        super( props );

        this.bloc = new MetaBloc();
    }

    render() {
        return (
            <BlocBuilder
                initialValue={new MetaState()}
                subject={this.bloc.getStateSubject()}
                builder={( currentState ) => {
                    return ( <div>
                            <div className="flex  flex-row">
                                <h2 className="text-3xl  font-bold">Produkt-Meta-Daten</h2>

                                <div className="ml-auto">
                                    <button className="flex  flex-row  items-center  |  space-x-3  |  cursor-pointer"
                                            onClick={() => this.bloc.setOpenState( 'settings', !( currentState.data.openStates?.settings ?? false ) )}>
                                    <span className="display-inlineblock  |  text-blue-600">
                                        {!( currentState.data.openStates?.settings ?? false ) && "Einstellungen"}
                                        {( currentState.data.openStates?.settings ?? false ) && "Schließen"}
                                    </span>
                                        <IoCogOutline size={30} color={"#3b82f6"}/>
                                    </button>
                                </div>
                            </div>

                            <hr className="my-6"/>

                            <div className="flex  flex-row  flex-wrap  |  space-x-4  -ml-4">

                                <div className="px-5  py-4  mb-4  |  rounded-lg  |  bg-gray-200  |  w-1/5">
                                    <h3 className="flex  flex-row  items-center  space-x-3  |  text-xl  font-medium  |  mb-4  |  pb-3  |  border-b-2  border-gray-300">
                                        <HiOutlineTag size={20}/>
                                        <span>NEU-Tags</span>
                                    </h3>

                                    <Button
                                        style={ButtonStyle.Default}
                                        onClick={( e ) => this.bloc.removeNewTags()}
                                        loading={( currentState.data.loadingStates?.removeNewTags === true )}>
                                        Alle NEU-Tags entfernen
                                    </Button>

                                    {/* TODO-wfp: display response from removing new tags */}
                                </div>

                                <div className="px-5  py-4  mb-4  |  rounded-lg  |  bg-gray-200  |  w-1/5">
                                    <h3 className="flex  flex-row  items-center  space-x-3  |  text-xl  font-medium  |  mb-4  |  pb-3  |  border-b-2  border-gray-300">
                                        <RiGitBranchLine size={20}/>
                                        <span>Sub-Brands</span>
                                    </h3>

                                    <div className="flex  flex-col  |  space-y-3">
                                        <Button
                                            style={ButtonStyle.Secondary}
                                            onClick={( e ) => this.bloc.importSubBrands()}
                                            loading={( currentState.data.loadingStates?.importSubBrands === true )}>
                                            Sub-Brands importieren
                                        </Button>

                                        <Button
                                            style={ButtonStyle.Default}
                                            onClick={( e ) => this.bloc.setSubBrands()}
                                            loading={( currentState.data.loadingStates?.setSubBrands === true )}>
                                            Sub-Brands setzen
                                        </Button>

                                        <p className="text-xxs  text-gray-400">
                                            Aktuell werden die Sub-Brands nur für die Marken LShop und Falk & Ross gesetzt.
                                            Eine Erweiterung der Sub-Brand Marken muss manuell angepasst werden.
                                        </p>
                                    </div>
                                </div>

                                <div className="px-5  py-4  mb-4  |  rounded-lg  |  bg-gray-200  |  w-1/5">
                                    <h3 className="flex  flex-row  items-center  space-x-3  |  text-xl  font-medium  |  mb-4  |  pb-3  |  border-b-2  border-gray-300">
                                        <RiPantoneLine size={20}/>
                                        <span>Personalisierbar</span>
                                    </h3>

                                    <div className="flex  flex-col  |  space-y-3">
                                        <Button
                                            style={ButtonStyle.Default}
                                            onClick={( e ) => this.bloc.setCustomizableAttribute()}
                                            loading={( currentState.data.loadingStates?.setCustomizableAttribute === true )}>
                                            "Personalisierbar" setzen
                                        </Button>
                                    </div>
                                </div>

                                <div className="px-5  py-4  mb-4  |  rounded-lg  |  bg-gray-200  |  w-1/5">
                                    <h3 className="flex  flex-row  items-center  space-x-3  |  text-xl  font-medium  |  mb-4  |  pb-3  |  border-b-2  border-gray-300">
                                        <RiFile2Line size={20}/>
                                        <span>Private Label setzen</span>
                                    </h3>

                                    <div className="flex  flex-col  |  space-y-3">
                                        <Button
                                            style={ButtonStyle.Default}
                                            onClick={( e ) => this.bloc.setPrivateLabelAttribute()}
                                            loading={( currentState.data.loadingStates?.setPrivateLabelAttribute === true )}>
                                            "Private Label" setzen
                                        </Button>
                                    </div>
                                </div>

                                <div className="px-5  py-4  mb-4  |  rounded-lg  |  bg-gray-200  |  w-1/5">
                                    <h3 className="flex  flex-row  items-center  space-x-3  |  text-xl  font-medium  |  mb-4  |  pb-3  |  border-b-2  border-gray-300">
                                        <BsWrench size={20}/>
                                        <span>Hilfs-Aktionen</span>
                                    </h3>

                                    <div className="flex  flex-col  space-y-3">
                                        <Button
                                            style={ButtonStyle.Default}
                                            onClick={( e ) => this.bloc.fixBaseProductsWithNoImages()}
                                            loading={( currentState.data.loadingStates?.fixBaseProductsWithNoImages === true )}>
                                            Fix Produkte ohne Bild
                                        </Button>

                                        <hr/>

                                        <Button
                                            style={ButtonStyle.Default}
                                            onClick={( e ) => this.bloc.updateBrandCategoryOrders()}
                                            loading={( currentState.data.loadingStates?.updateBrandCategoryOrders === true )}>
                                            Marken-Reihenfolge aktualisieren
                                        </Button>

                                        <Button
                                            style={ButtonStyle.Default}
                                            onClick={( e ) => this.bloc.addSizeGuideLinksToDl()}
                                            loading={( currentState.data.loadingStates?.addSizeGuideLinksToDl === true )}>
                                            Größentabellen-Links eintragen
                                        </Button>

                                        <Button
                                            style={ButtonStyle.Default}
                                            onClick={( e ) => this.bloc.setFilterColors()}
                                            loading={( currentState.data.loadingStates?.setFilterColors === true )}>
                                            Filter-Farben setzen
                                        </Button>

                                        <Button
                                            style={ButtonStyle.Default}
                                            onClick={( e ) => this.bloc.deleteEmptyVariableProducts()}
                                            loading={( currentState.data.loadingStates?.deleteEmptyVariableProducts === true )}>
                                            Varianten-Produkte ohne Varianten löschen
                                        </Button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    );
                }}
            />
        );
    }
}

export default MetaPage;
