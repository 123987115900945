import { ProductSettingsArticleType, ProductSettingsFetchByMode, ProductSettingsImportMode } from '../ProductsBloc/ProductsState';

export default class ImagesState {
    loadingStates: any                   = {};
    openStates: any                      = {};
    settingsState: ImagesSettings        = {
        imageImportMode:      "auto",
        importMode:           "single",
        importFrom:           "weclapp",
        fetchByMode:          "sku",
        handleExistingImages: "replace",
        articleType:          "variantArticle",
    };
    currentArticleNumber: string         = '';
    weClappData: any                     = null;
    weClappImages: ProductImagesType     = null;
    wooCommerceImages: ProductImagesType = null;

    get totalWeClappImages() {
        let images: number = 0;
        images += ( this.weClappImages?.main?.length ?? 0 );
        images += ( this.weClappImages?.variations?.length ?? 0 );

        return images;
    }

    get hasWeClappImages() {
        return this.totalWeClappImages > 0;
    }

    get totalWooCommerceImages() {
        let images: number = 0;
        images += ( this.wooCommerceImages?.main?.filter( ( image ) => image.id !== 0 )?.length ?? 0 );
        images += ( this.wooCommerceImages?.variations?.length ?? 0 );

        return images;
    }

    get hasWooCommerceImages() {
        return this.totalWooCommerceImages > 0;
    }
}

export type ProductImagesType = {
    main: Array<any>,
    variations: Array<any>,
} | null;

export type ImageSettingsImportFrom = "weclapp" | "StanleyStella";
export type ImagesSettingsImportMode = "auto" | "manual";
export type ImageSettingsHandleExistingImages = "replace" | "add";
export type ImagesSettings = {
    [key: string]: string;
    imageImportMode: ImagesSettingsImportMode;
    importFrom: ImageSettingsImportFrom;
    importMode: ProductSettingsImportMode;
    fetchByMode: ProductSettingsFetchByMode;
    handleExistingImages: ImageSettingsHandleExistingImages;
    articleType: ProductSettingsArticleType;
}
