import React from 'react';
import './SegmentToggle.scss';

export type SegmentToggleProps = {
    options: { [key: string]: string; },
    value: string,
    id: string,
    onChange: Function;
    className?: string | undefined;
}


const defaultProps: SegmentToggleProps = {
    options:   {},
    value:     '0',
    id:        'segmentToggle',
    onChange:  () => {},
    className: '',
};

function SegmentToggle( props: SegmentToggleProps = defaultProps ) {

    let segments = [];

    for ( let [key, val] of Object.entries( props.options ) ) {
        segments.push(
            <div key={`${props.id}-${key}`}>
                <input type="radio" name={props.id} id={`${props.id}-${key}`}
                       defaultChecked={props.value === key}
                       onChange={( e ) => { props.onChange( e, key, val );}}
                       className={`segment-toggle__radio`}/>
                <label htmlFor={`${props.id}-${key}`}>{val}</label>
            </div>,
        );
    }

    return (
        <div className={`segment-toggle  |  ${props.className ?? ''}`}>
            {segments}
        </div>
    );
}

export default SegmentToggle;
