export default class ProductsState {
    settingsOpen: boolean             = false;
    articleNumberIsValidated: boolean = false;
    currentArticleNumber: string | undefined;
    weClappData: any | undefined;
    wooCommerceData: any | undefined;
    loadingStates: any                = {};
    openStates: any                   = {};
    settingsState: ProductsSettings   = {
        handleExistingProducts: 'skip',
        fetchByMode:            'sku',
        articleType:            'variantArticle',
        importMode:             'single',
        transferMode:           'create',
    };
}

export type ProductsSettings = {
    [key: string]: string;
    handleExistingProducts: ProductSettingsHandleExistingProducts;
    fetchByMode: ProductSettingsFetchByMode;
    articleType: ProductSettingsArticleType;
    importMode: ProductSettingsImportMode;
    transferMode: ProductSettingsTransferMode;
}

export type ProductSettingsHandleExistingProducts = "skip" | "update";
export type ProductSettingsFetchByMode = "sku" | "id";
export type ProductSettingsArticleType = "article" | "variantArticle";
export type ProductSettingsImportMode = "single" | "batch";
export type ProductSettingsTransferMode = "create" | "delete";
