import React, { Component, createRef } from 'react';
import CustomersBloc from '../../../blocs/CustomersBloc/CustomersBloc';
import { GrWordpress, IoCogOutline } from 'react-icons/all';
import { BlocBuilder } from 'react-blocbuilder';
import CustomersState from '../../../blocs/CustomersBloc/CustomersState';
import SegmentToggle from '../../SegmentToggle/SegmentToggle';
import Button, { ButtonStyle } from '../../buttons/Button';
import { ReactComponent as WeClappLogo } from '../../../images/logos/weclapp-ccolor.svg';
import MessageBox, { MessageBoxType } from '../../MessageBox/MessageBox';
import * as _ from 'lodash';

class CustomersPage extends Component<any, any> {
    private bloc: CustomersBloc;
    private customerNrRef: React.RefObject<any> = createRef();

    constructor( props: any, context: any ) {
        super( props, context );

        this.bloc = new CustomersBloc();
    }

    handleSubmit = async ( e: React.MouseEvent<HTMLButtonElement> ) => {
        e.preventDefault();
        let customerNumber = this.customerNrRef.current.value;

        if ( !_.isEmpty( this.bloc.currentState.weClappData ) ) {
            await this.bloc.syncCustomer( customerNumber, this.bloc.currentState.settingsState.fetchByMode );
            return;
        }

        this.bloc.loadCustomerData( customerNumber, this.bloc.currentState.settingsState.fetchByMode );
        this.bloc.loadWooCommerceCustomer( customerNumber, this.bloc.currentState.settingsState.fetchByMode );
    };

    handleLoadWooCommerceData = async () => {
        let customerNumber = this.customerNrRef.current.value;
        await this.bloc.loadWooCommerceCustomer( customerNumber, this.bloc.currentState.settingsState.fetchByMode );
    };

    render() {
        return (
            <BlocBuilder
                initialValue={new CustomersState()}
                subject={this.bloc.getStateSubject()}
                builder={( currentState ) => {
                    return (
                        <div>
                            <div className="flex  flex-row">
                                <h2 className="text-3xl  font-bold">Kunden synchron.</h2>

                                <div className="ml-auto">
                                    <button className="flex  flex-row  items-center  |  space-x-3  |  cursor-pointer"
                                            onClick={() => this.bloc.setOpenState( 'settings', !( currentState.data.openStates?.settings ?? false ) )}>
                                    <span className="display-inlineblock  |  text-blue-600">
                                        {!( currentState.data.openStates?.settings ?? false ) && "Einstellungen"}
                                        {( currentState.data.openStates?.settings ?? false ) && "Schließen"}
                                    </span>
                                        <IoCogOutline size={30} color={"#3b82f6"}/>
                                    </button>
                                </div>
                            </div>

                            <hr className="my-6"/>

                            <div className="mb-6">
                                <h3 className="text-xl  font-medium  |  mb-4">Gebe die gewünschte Kunden-Nr. ein</h3>

                                <div
                                    className="input-group  |  flex  flex-col  |  px-3  py-2  |  border-2  border-gray-300  |  rounded-md  |  bg-white  |  focus-within:border-gray-400">
                                    <div className="flex  flex-row  |  space-x-4">
                                        <div className="flex  flex-col  |  w-full">
                                            <label className="relative  |  text-sm  font-medium  text-gray-500"
                                                   htmlFor="singleArticleNumber"
                                                   style={{ top: '-1px' }}>Kunden-Nr. / ID</label>
                                            <input type="text" className="focus:outline-none  font-medium  |  w-full"
                                                   defaultValue={"26182"}
                                                   onChange={() => {
                                                       this.bloc.setWooCommerceData( null );
                                                       this.bloc.setWeClappData( null );
                                                   }}
                                                   id="customerId" ref={this.customerNrRef}/>
                                        </div>

                                        <div className="ml-auto  flex-shrink-0  |  flex  flex-row  space-x-3  items-center  |">
                                            <SegmentToggle
                                                options={{
                                                    'customerNumber': 'Kunden Nr.',
                                                    'id':             'ID',
                                                }}
                                                value={currentState.data.settingsState?.fetchByMode ?? 'customerNumber'} id={'fetchByMode'}
                                                onChange={( e: any, val: string, _: string ) => { this.bloc.setSettings( 'fetchByMode', val ); }}
                                                className="bg-gray-100"
                                            />

                                            <Button
                                                style={( !currentState.data.weClappData ) ? ButtonStyle.Secondary
                                                                                          : ( ( currentState.data.loadingStates?.wooCommerceData ?? false ) )
                                                                                            ? ButtonStyle.Disabled : ButtonStyle.Default}
                                                onClick={( e ) => this.handleSubmit( e )}
                                                loading={( currentState.data.loadingStates?.customerNumber === true )}>
                                                {!currentState.data.weClappData && "Validieren"}
                                                {currentState.data.weClappData && "Synchron."}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="flex  flex-row  |  space-x-4">
                                <div className="px-5  py-4  |  rounded-lg  |  bg-gray-200  |  w-1/2">
                                    <h3 className="flex  flex-row  items-center  space-x-3  |  text-xl  font-medium  |  mb-4  |  pb-3  |  border-b-2  border-gray-300">
                                        <WeClappLogo width={20} color={"#12da9d"}/>
                                        <span>WeClapp Daten</span>
                                    </h3>

                                    {/* Empty State */}
                                    {!currentState.data.weClappData &&
                                     <div>
                                         <div className="font-medium  text-gray-500  |  mb-4">
                                             Noch keine Daten geladen
                                         </div>
                                     </div>}

                                    {( ( currentState.data.weClappData?.length == 0 ) ?? false ) &&
                                     <div>
                                         <MessageBox>
                                             Keine Daten gefunden
                                         </MessageBox>
                                     </div>}

                                    {/* Data Table */}
                                    {( currentState.data.weClappData && _.isPlainObject( currentState.data.weClappData ) ) &&
                                     <div className="">
                                         <div className="flex  flex-row  space-x-5  |  font-bold">
                                             <div className="text-left  |  w-36">Key</div>
                                             <div className="text-left">Value</div>
                                         </div>
                                         <div>
                                             {_.toPairs( currentState.data.weClappData ).map( ( value: any, index: number ) =>
                                                 <div key={value[0]}
                                                      className={`flex  flex-row  |  space-x-5  |  text-xs  |  ${( index % 2 )
                                                                                                                 ? 'bg-gray-100  |  py-2  px-3'
                                                                                                                 : 'py-4  px-3'}  |  rounded`}>
                                                     <div className="font-bold  |  w-36  |  flex-shrink-0">{value[0]}</div>
                                                     <div className="break-all  |  font-mono">
                                                         <pre>{JSON.stringify( value[1], null, 2 )}</pre>
                                                     </div>
                                                 </div> )}
                                         </div>
                                     </div>
                                    }
                                </div>

                                <div className="px-5  py-4  |  rounded-lg  |  bg-gray-200  |  w-1/2">
                                    <h3 className="flex  flex-row  items-center  space-x-3  |  text-xl  font-medium  |  mb-4  |  pb-3  |  border-b-2  border-gray-300">
                                        <GrWordpress size={20} color={"#1d4ed8"}/>
                                        <span>WooCommerce Daten</span>
                                    </h3>

                                    {/* Empty State */}
                                    {!currentState.data.wooCommerceData &&
                                     <div>
                                         <div className="font-medium  text-gray-500  |  mb-4">
                                             Noch keine Daten geladen
                                         </div>

                                         <Button
                                             style={( currentState.data.currentCustomerNumber ) ? ButtonStyle.Default
                                                                                                : ButtonStyle.Disabled}
                                             loading={( currentState.data.loadingStates?.wooCommerceData === true )}
                                             onClick={( e ) => this.handleLoadWooCommerceData()}>
                                             Neu laden
                                         </Button>
                                     </div>}

                                    {/* Data Table */}
                                    {currentState.data.wooCommerceData &&
                                     <div className="">
                                         {_.isObject( currentState.data.wooCommerceData ) &&
                                          <div>
                                              <div className="flex  flex-row  space-x-5  |  font-bold">
                                                  <div className="text-left  |  w-36">Key</div>
                                                  <div className="text-left">Value</div>
                                              </div>

                                              {_.toPairs( currentState.data.wooCommerceData ).map( ( value: any, index: number ) =>
                                                  <div key={value[0]}
                                                       className={`flex  flex-row  |  space-x-5  |  text-xs  |  ${( index % 2 )
                                                                                                                  ? 'bg-gray-100  |  py-2  px-3'
                                                                                                                  : 'py-4  px-3'}  |  rounded`}>
                                                      <div className="font-bold  |  w-36  |  flex-shrink-0">{value[0]}</div>
                                                      <div className="break-all  |  font-mono">
                                                          <pre>{JSON.stringify( value[1], null, 2 )}</pre>
                                                      </div>
                                                  </div> )
                                              }
                                          </div>}

                                         {_.isString( currentState.data.wooCommerceData ) &&
                                          <MessageBox
                                              type={MessageBoxType.Error}>
                                              {currentState.data.wooCommerceData}
                                          </MessageBox>}
                                     </div>
                                    }
                                </div>
                            </div>
                        </div>
                    );
                }}
            />
        );
    }
}

export default CustomersPage;
