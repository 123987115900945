import axios, { AxiosResponse } from 'axios';
import * as https from 'https';
import _ from 'lodash';
import { CustomersSettingsFetchByMode } from '../blocs/CustomersBloc/CustomersState';

class WeClappService {

    // static PROXY_BASE_URL: string = 'http://local.webshop.corpotex.de/wp-json/cptx/v1/weclapp/proxy/';
     static PROXY_BASE_URL: string = 'https://webshop.corpotex.de/wp-json/cptx/v1/weclapp/proxy/';
    //static PROXY_BASE_URL: string = 'https://corpotex.de/wp-json/cptx/v1/weclapp/proxy/';
    // static PROXY_BASE_URL: string = 'https://local.corpotex.de/wp-json/cptx/v1/weclapp/proxy/';

    /**
     * Load article data from WeClapp.
     *
     * @param articleNumber
     * @param fetchBy
     * @return API-Response
     */
    loadArticle = async ( articleNumber: string, fetchBy: "id" | "sku" = "sku" ) => {
        let params = `articleNumber-eq=${articleNumber}`;

        if ( fetchBy === "id" ) {
            params = `id-eq=${articleNumber}`;
        }

        let fieldsParams = WeClappService.reducedFieldsParams;
        params += fieldsParams;

        let result: AxiosResponse = await axios.get(
            WeClappService.getEndpointUrl( `article?${params}` ),
            {
                httpsAgent: new https.Agent( {
                    rejectUnauthorized: false,
                } ),
            } );
        return result?.data;
    };

    /**
     * Load variantArticle data from WeClapp.
     *
     * @param articleNumber
     * @param fetchBy
     * @param additionalParams
     * @return API-Response
     */
    loadVariantArticle = async ( articleNumber: string, fetchBy: "id" | "sku" = "sku", additionalParams: string = '' ) => {
        let params = `variantArticleNumber-eq=${articleNumber}`;

        if ( fetchBy === "id" ) {
            params = `id-eq=${articleNumber}`;
        }

        if ( !_.isEmpty( additionalParams ) ) {
            params += `&${additionalParams}`;
        }

        // let fieldsParams = WeClappService.reducedFieldsParams;
        // params += fieldsParams;

        let result: AxiosResponse = await axios.get(
            WeClappService.getEndpointUrl( `variantArticle?${params}` ),
            {
                httpsAgent: new https.Agent( {
                    rejectUnauthorized: false,
                } ),
            } );

        return result?.data;
    };

    loadArticleImage = async ( articleId: string, articleImageId: string, scaleWidth: number = 1900, scaleHeight: number = 2000 ) => {
        let params = `articleImageId=${articleImageId}&scaleWidth=${scaleWidth}&scaleHeight=${scaleHeight}`;

        let result: AxiosResponse = await axios.get(
            WeClappService.getEndpointUrl( `article/id/${articleId}/downloadArticleImage?${params}` ),
            {
                httpsAgent: new https.Agent( {
                    rejectUnauthorized: false,
                } ),
            } );

        return result;
    };

    loadCustomer = async ( customerNr: string, fetchBy: CustomersSettingsFetchByMode = "customerNumber" ) => {
        let params = `customerNumber-eq=${customerNr}`;

        if ( fetchBy == "id" ) {
            params = `id-eq=${customerNr}`;
        }

        let result: AxiosResponse = await axios.get(
            WeClappService.getEndpointUrl( `customer/?${params}` ),
            {
                httpsAgent: new https.Agent( {
                    rejectUnauthorized: false,
                } ),
            } );

        return result?.data;
    };

    loadAllManufacturers = async () => {
        let params = `active-eq=true`;

        let result: AxiosResponse = await axios.get(
            WeClappService.getEndpointUrl( `manufacturer?${params}` ),
            {
                httpsAgent: new https.Agent( {
                    rejectUnauthorized: false,
                } ),
            } );
        return result?.data;
    }

    static get reducedFieldsParams() {
        let fields = [
            'id',
            'active',
            'articleImages',
            'articleNetWeight',
            'articleNumber',
            'articlePrices',
            'articleType',
            'customAttributes',
            'description',
            'manufacturerId',
            'manufacturerName',
            'name',
            'tags',
        ];

        return `&properties=${fields.join( ',' )}`;
    }

    static get minimalFieldParams() {
        let fields = [
            'id',
            'active',
            'articleNumber',
            'articleImages',
            'name',
            'customAttributes',
        ];

        return `&properties=${fields.join( ',' )}`;
    }

    static getEndpointUrl = ( endpoint: string ) => {
        return WeClappService.PROXY_BASE_URL + endpoint;
    };
}

export default WeClappService;
